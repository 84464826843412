
import React from 'react';
import Slider from 'react-slick';
import '../../Testimonials/Testimonials.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import emoji from '../../Assets/midico1.png';
import emoji2 from '../../Assets/midico2.png';
import emoji6 from '../../Assets/emoji6.png';
import wave from '../../Assets/wave.svg';
import kite from '../../Assets/kite.svg';

const Testimonials = () => {
    const testimonials = [
        {
            name: "Krita Anil",
            role: "Parent",
            emoji: emoji,
            description1: "“I had a wonderful experience at Baldwin Girls’ High school.The teachers were excellent and made learning enjoyable. There were many activities to participate in, which helped me grow and make lasting friendships. The school's facilities were top-notch, and the environment was very supportive.Additionally, the school provided ample opportunities for personal development and leadership through various programs.”",
           
        },
        {
            name: "vaibhav tathare",
            role: "Parent",
            emoji: emoji6,
            description1: " “Before contacting Baldwin Girls’ High school, Shifting my daughter Miss Viditi Vaibhav Tathare From one board to another board ,from one state to another state, From one language to another language, was a nightmare.",
            description2:"Thank you to Principal Mrs Asha, Class Teacher Mrs Mary, language teacher Mrs Parimala and all Staff members for supporting and guiding. Now, Viditi is more confident and agile.”",

           

        },
        
        
        {
            name: "Kiran Chhabria",
            role: "Parent",
            emoji: emoji2,
            description1: "“Baldwin Girls' High School is doing excellent in all fields, especially in giving a lot of exposure to children, not just in academics but in other co-curricular activities as well. Very nicely planned and organized academic program.",
            description2: " Good efforts by all teachers. I am very grateful to all teachers, the HODs, and the Principal. My child is being groomed very well.”",           


        },        
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative mt-margin parentallign'>
            <img className='kite' src={kite} alt='kite' />
            <img className='wave' src={wave} alt='wave' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>Testimonials</h2>
                </div>
                <p className='mb-4 fw-600' style={{color:"#2D306F"}}>What Parents have to say about BGHS</p>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            name={testimonial.name}
                            role={testimonial.role}
                            emoji={testimonial.emoji}
                            description1={testimonial.description1}
                            description2={testimonial.description2}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;