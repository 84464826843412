import React from 'react'
import '../../PrePrimaryComponents/Choice/Choice.css'
import choice_img from '../../Assets/choice-primary.png'
import ChoiceCard from '../../PrePrimaryComponents/ChoiceCard/ChoiceCard';

const choice = [
    {
        choicetitle:"Why are we the best choice for your child?",        
        choicedescription: "Our Class 3-5 programme is meticulously crafted to foster academic brilliance, holistic growth, and a love for learning, providing the ideal foundation for your child's future success.",
        number: "01" , 
        content: "Holistic Curriculum",
        sub_content:"Our carefully crafted curriculum prioritizes academic rigor, social-emotional well-being, and the development of core values with lots of activities and hands-on experience, ensuring a well-rounded education for every student.",

        number2: "02" , 
        content2: "Character Development",
        sub_content2:"Our school places a strong emphasis on character development, instilling values such as integrity, resilience, empathy, and responsibility in our students, shaping them into compassionate and ethical individuals.",


        number3: "03" , 
        content3: "Well-Rounded Education",
        sub_content3:"Well-Rounded Education: Knowledge and information blended with activities.",

        choiceimage:choice_img,    
    },    
];
const Choice = () => {
  return (
    <div>
        {choice.map((choice, index) => (
                    <ChoiceCard
                    key={index} 
                    choicetitle={choice.choicetitle}
                    choicedescription={choice.choicedescription}
                    number={choice.number}
                    content={choice.content}
                    sub_content={choice.sub_content}
                    number2={choice.number2}
                    content2={choice.content2}
                    sub_content2={choice.sub_content2}
                    number3={choice.number3}
                    content3={choice.content3}
                    sub_content3={choice.sub_content3}
                    choiceimage={choice.choiceimage}
                    />
                ))}
    </div>
  )
}
export default Choice