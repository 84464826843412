import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import PrePrimary from './Pages/PrePrimary';
import Aboutus from './Pages/Aboutus';
import Primary from './Pages/Primary';
import MiddleSchool from './Pages/MiddleSchool';
import HighSchool from './Pages/HighSchool';
import Events from './Pages/Events';
import CoCurricular from './Pages/CoCurricular';
import Sports from './Pages/Sports';
import Campus from './Pages/Campus';
import Header from './Components/Header/Header';
import Thankyou from './Pages/Thankyou';
import Alumni from './Pages/Alumni';
import Footer from './Components/Footer/Footer';

// const ScrollToTop = () => {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 100); // Adding a slight delay to ensure scroll happens after the render
//   }, [pathname]);

//   return null;
// };

// const ScrollToTop = () => {
//   const { pathname, state } = useLocation();

//   useEffect(() => {
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//       if (state && state.targetSection) {
//         const targetSection = document.querySelector(state.targetSection);
//         if (targetSection) {
//           targetSection.scrollIntoView({ behavior: 'smooth' });
//         }
//       }
//     }, 100); // Adding a slight delay to ensure scroll happens after the render
//   }, [pathname, state]);

//   return null;
// };

function App() {
  return (
    <div className="App">
      <Router>
        {/* <ScrollToTop /> */}
        <Header />
        <Routes>
        <Route path="/" element={<Home />} />

          <Route path="/PrePrimary" element={<PrePrimary />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Primary" element={<Primary />} />
          <Route path="/MiddleSchool" element={<MiddleSchool />} />
          <Route path="/HighSchool" element={<HighSchool />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/CoCurricular" element={<CoCurricular />} />
          <Route path="/Sports" element={<Sports />} />
          <Route path="/Campus" element={<Campus />} />
          <Route path="/Alumni" element={<Alumni />} />
          <Route path="/Thankyou" element={<Thankyou  />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
