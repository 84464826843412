import React from 'react'
import eventsimg from '../../Assets/campus.png'
import eventsimgmobile from '../../Assets/campusmobile.png'
import './Eventsbanner.css'
const Eventsbanner = () => {
  return (
    <div className='position-relative dark'>
      <img className='w-100 mob-banner-h desk-block' src={eventsimg} />
      <img className='w-100 mob-banner-h mob-block' src={eventsimgmobile} />
      <div className='event-banner-content'>
        <h2>INFRASTRUCTURE</h2>
        <div className='breadcrumbs'>
          <span className='border-left'></span>
          <span>Campus Amenities</span>
          <span className='grey-round'></span>
          <span>Recreational Spaces</span>
          <span className='grey-round'></span>
          <span>Campus Environment</span>
          <span className='border-right'></span>
        </div>
      </div>
    </div>
  )
}

export default Eventsbanner