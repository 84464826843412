import React from 'react'
import aboutbanner from '../../Assets/about-banner.png'
import './AboutBanner.css'
const AboutBanner = () => {
 
  return (
    <div>
        <div className='position-relative dark'>
            <img className='w-100' src={aboutbanner} alt=''/>
            
            <h1  className='aboutus'>About Us</h1>
        </div>
    </div>
  )
}

export default AboutBanner