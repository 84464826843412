import React from 'react'
// import './History.css'
import historyImg from '../../Assets/sports2.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const history = [
    {
        schooltitle:"Explore Sports Activities",        
        schooldescription: "Sporting activities find an important place in our institution. We recognize that play is one of the most natural and important forms of recreation. It is also the best way to improve one's health and spirits.",
        schooldescription2: "Every student at Baldwin Girls’ High School gets an opportunity to choose from a wide range of 18 outdoor and indoor sports under the motivation and guidance of skilled coaches and trainers." , 
        schooldescription3: "The performance of our students in various tournaments at all levels is very encouraging. Some of our star performers are Loraine Verghese, Kirthi Rao, Natasha Sagar, Aishwarya G.M., Sangeetha Rao, Arhatha Magavi, and Nirupama Sunderraj. Facilities for indoor sports are also provided to our students. Our swimming pool is built to international standards. We are proud of our swimmers and athletes who have won laurels at the state and international levels.",
        image: historyImg,       
    },

    


   

    
];
const Ceremony = () => {
  return (
    <div className='History'>
        {history.map((history, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={history.schooltitle}
                    schooldescription={history.schooldescription}
                    schooldescription2={history.schooldescription2}
                    schooldescription3={history.schooldescription3}
                    image={history.image}
                    />
                ))}
    </div>
  )
}

export default Ceremony