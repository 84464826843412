import React from 'react'
import './Accordion.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
const Accordion_ACRA = ({sections}) => {
    return (
        <div>
            <Accordion >
                {sections.map((section, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{section.label}</Accordion.Header>
                        <Accordion.Body>
                        <Row >
                                <Col md={4} className='infowidth'>
                            <p className='subcontent fw-600'>{section.subcontent}</p>
                            <p className='content'>{section.content}</p>
                            <p className='content'>{section.content2}</p>
                            <p className='content'>{section.content3}</p>
                            <p className='content'>{section.content4}</p>
                          

                                </Col>
                                <Col md={4} className='infowidth'>
                                <p className='subcontent fw-600' style={{height:"25px"}}>{section.subcontentt}</p>
                                <p className='content'>{section.contentt}</p>
                                <p className='content'>{section.content22}</p>
                              

                                </Col>

                                <Col md={4} className='infowidth'>
                                <p className='subcontent fw-600' style={{height:"25px"}}>{section.subcontenttt}</p>
                                <p className='content'>{section.content23}</p>
                                <p className='content'>{section.content24}</p>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>      
        </div>
    )
}

export default Accordion_ACRA