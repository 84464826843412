import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Vedio from '../Components/Vedio/Vedio'
import AdmissionNews from '../Components/AdmissionNews/AdmissionNews'
import Welcome from '../Components/Welcome/Welcome'
import Academy from '../Components/Academy/Academy'
import Sets from '../Components/Sets/Sets'
import Beyondbooks from '../Components/Beyondbooks/Beyondbooks'
import Testimonials from '../Components/Testimonials/Testimonials'
import Announcement from '../Components/Announcement/Announcement'
import News from '../Components/News/News'
import Infrastructure from '../Components/CampusComponents/Infrastructure/Infrastructure'
import Admission from '../Components/Admission/Admission'
import Findus from '../Components/Findus/Findus'
import Featured from '../Components/Featured/Featured'
import Information from '../Components/PrePrimaryComponents/Information/Information';
import SuccessStory from '../Components/PrePrimaryComponents/SuccessStory/SuccessStory';
import Aboutschool from '../Components/AboutSchool/Aboutschool';
const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.targetSection) {
      const targetSection = document.querySelector(location.state.targetSection);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (location.hash) {
      const targetSection = document.querySelector(location.hash);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);



  
  return (
    <div>
        <Vedio/>
        <AdmissionNews/>
        <Aboutschool/>     
        <Welcome/>  
        <Academy/>
        <Sets/>
        <Beyondbooks/>
        <Testimonials/>
        <Announcement/>
        <News/>
        <SuccessStory/>
        <Infrastructure/>
        <Admission/>
        <div style={{marginBottom:"50px"}}>

        <Information/>
        </div>
        <Findus/>
        <Featured/>       
    </div>
  )
}

export default Home
