import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion_ACRA from '../AccordionInfoHigh/Accordion'

const CurriculumCard = ({ curriculumImage,curriculumTitle,curriculumSubTitle,kite2 }) => {
    const sections = [
        {
            label: 'Subjects',
            subcontent: 'Group 1 (Compulsory)',
            content: 'English',
            content2: 'Hindi/Kannada',
            content3: 'History & Civics',
            content4:'Geography ',

            

            label: 'Subjects', 
            subcontentt: 'Group 2',           
            contentt: ' Mathematics',
            content22: 'Physics, Chemistry, Biology / Commercial Studies ',

            subcontenttt: 'Group 3',  
            content23: ' Computer Application',
            content24:'Home Science',
        },
    ];
    return (
        <div className='position-relative school-w'>
            <img className='w-100 kite2' src={kite2} alt='kite' />
            <Row>
                <Col xs={12} md={4} className='mob-p-0'>
                    <img className='w-100' src={curriculumImage} alt='school girl' />
                </Col>
                <Col xs={12} md={8} style={{ alignSelf: "center" }}>
            <div className='container' >
                <div className='position-relative mb-5'>
                    <h2 className='line'>{curriculumTitle}</h2>
                </div>
                <p className='mb-4 fw-600' style={{color:"#2D306F"}}>{curriculumSubTitle}</p>
            </div>
                    <div className='accor-width' >
                        <Accordion_ACRA sections={sections} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default CurriculumCard;
