import React from 'react'
import AboutBanner from '../Components/AboutComponent/AboutBanner/AboutBanner'
import History from '../Components/AboutComponent/History/History'
import Vision from '../Components/AboutComponent/Vision/Vision'
import Crest from '../Components/AboutComponent/Crest/Crest'
import Prayer from '../Components/AboutComponent/Prayer/Prayer'
import Leadership from '../Components/AboutComponent/Leadership/Leadership'
// import Houses from '../Components/AboutComponent/Houses/Houses'
import HouseCards from '../Components/AboutComponent/HouseCards/HouseCards'
import AdmissionAbout from '../Components/AboutComponent/AdmissionAbout/AdmissionAbout'
import OtherSectionAbout from '../Components/AboutComponent/OtherSectionAbout/OtherSectionAbout'

const Aboutus = () => {
  return (
    <div style={{background:"#FFF"}}>
        <AboutBanner/>
        <History/>        
        <Vision/>
        <Crest/>
        <Prayer/>
        <Leadership/>
        {/* <Houses/> */}
        <HouseCards/>
        <AdmissionAbout/>
        <OtherSectionAbout/>
    </div>
  )
}

export default Aboutus