import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Academy from '../../Academy/Academy';
import schoolgirl1 from '../../Assets/schoolgirl1.png'
import schoolgirl2 from '../../Assets/schoolgirl2.png'
import schoolgirl3 from '../../Assets/schoolgirl3.png'
import schoolgirl4 from '../../Assets/schoolgirl4.png'
import leftsideone from '../../Assets/leftsideone.svg'
import leftsidetwo from '../../Assets/leftsidetwo.svg'
import rightside from '../../Assets/rightside.svg'
import './OtherSections.css'
const OtherSections = () => {
    return (
        <div className='position-relative pt-padding'>

        <img className="left-img1" src={leftsideone} alt='left-img1' />
        <img className="left-img2"  src={leftsidetwo} alt='left-img2' />
        <img className="right-img"  src={rightside} alt='right-img' />
        <div className='container'>
            <div className='position-relative mb-5'>
                <h2 className='line'>LEARN MORE ABOUT OTHER SECTIONS</h2>
            </div>
            <Row>
            <Col xs={6} md={3}>
                    <div className='card flex-coloumn'>
                        <img src={schoolgirl1} alt='school girl' />
                        <div className='card2 flex-coloumn'>
                            <h3>Pre-Primary
</h3>
                            <p>PRE-KG to Standard 2</p>
                        </div>
                        <Link to={{ pathname: "/PrePrimary",}} className='custom-btn'>Explore Courses</Link>
                    </div>
                </Col>
                <Col xs={6} md={3}>
                    <div className='card flex-coloumn'>
                        <img src={schoolgirl2} alt='school girl' />
                        <div className='card2 flex-coloumn'>
                            <h3>Primary School</h3>
                            <p>Standard 3 to Standard 5</p>
                        </div>
                        <Link to={{ pathname: "/Primary",}} className='custom-btn' >Explore Courses</Link> 
                    </div>
                </Col>
                <Col xs={6} md={3}>
                    <div className='card flex-coloumn'>
                        <img src={schoolgirl4} alt='school girl' />
                        <div className='card2 flex-coloumn'>
                            <h3> High School</h3>
                            <p>Standard 9 to Standard 10</p>
                        </div>
                        <Link to={{ pathname: "/HighSchool",}} className='custom-btn'>Explore Courses</Link> 
                    </div>
                </Col>
                <Col xs={6} md={3} style={{alignSelf:"center"}}>                                         
                <Link to={{ pathname: "/", hash: "#ACADEMY", state: { targetSection: "#ACADEMY" } }} className='fw-800 card text-center' style={{ color: "#5959C5" }}>Back to home page</Link>                  
                </Col>
            </Row>
        </div>
        </div>

    )
}

export default OtherSections