import React from 'react';
import GalleryUpdate from '../../GalleryUpdate';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import galeryp1 from '../../Assets/galleryprimary/galleryprimary1.JPG';
import galeryp2 from '../../Assets/galleryprimary/galleryprimary2.JPG';
import galeryp3 from '../../Assets/galleryprimary/galleryprimary3.JPG';
import galeryp4 from '../../Assets/galleryprimary/galleryprimary4.JPG';
import galeryp5 from '../../Assets/galleryprimary/galleryprimary5.JPG';
import galeryp6 from '../../Assets/galleryprimary/galleryprimary6.JPG';
import galeryp7 from '../../Assets/galleryprimary/galleryprimary7.JPG';
import galeryp from '../../Assets/galleryprimary/galleryprimary8.JPG';

const images = [galeryp, galeryp1, galeryp2, galeryp3, galeryp4, galeryp5, galeryp6, galeryp7];
const Gallery = () => {
    return (
        <div>
            <GalleryUpdate images={images} />
        </div>
    );
};


export default Gallery;
