import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Welcome.css';
import FormComponent from '../Form/Form'
import skywire from '../Assets/skywire.svg';
import design from '../Assets/design.svg';
import rectangle from '../Assets/Rectangle.svg';

const Welcome = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='mt-100 position-relative top200'>
                        <img className='rect2' src={rectangle} alt=''/>
            <Container>
                <Row className='mb-5 flex-direction welcome'>
                    <Col md={7}  className='flex-coloumn mob-5 position-relative mob-p-00' style={{alignItems: "start"}}>
                        <img className='rect' src={skywire} alt=''/>
                        <img className='rect3' src={design} alt=''/>
                        <div className='position-relative'>
                           <h2 className='line' data-aos="fade-right" data-aos-duration="1500">Welcome TO BGHS </h2>
                        </div>
                        <p data-aos="fade-right" data-aos-duration="1500" className='poppins' style={{color: "#2D306F;",}}>From the moment your daughter arrives at Baldwin Girls’ High School, she will be encouraged, educated and enthralled by a dedicated team of professional teachers and staff with an aim to make every day at School a memorable one.</p>
                        <p data-aos="fade-right"
    data-aos-duration="1500" className='poppins' style={{color: "#2D306F;",}}>Baldwin Girls’ High School is a place where girls thrive and acquire learning and social skills that will last a lifetime. Our Girls are inspired to achieve and we ensure that your daughter develops at the pace that best suits her. As she progresses through the school she will learn to be a part of a team and to take individual responsibility. Our strong sense of commitment and dedication prepares our girls for life beyond school. </p>
                        <Link to={{ pathname: "/Aboutus",}} className='custom-btn'>More about us</Link> 
                    </Col>
                    <Col md={5} className='mt-marginn'>
                        <FormComponent/>
                    </Col>
                </Row>
               
            </Container>
        </div>
    )
}

export default Welcome