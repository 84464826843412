import React from 'react';
import ratingstar from '../Assets/rating-star.svg';
import quote from '../Assets/quote.svg';

const TestimonialCard = ({ name, role, emoji, description1, description2, description3}) => {
    return (
        <div>
     
         
                <div className='custom-card testi'>
                    <div className='d-flex' style={{ alignItems: "flex-start" }}>
                        <img src={emoji} alt='emoji' />
                        <div className='flex-coloumn card-details' style={{ gap: "5px", paddingLeft: "25px" }}>
                            <h3>{name}</h3>
                            <p>{role}</p>
                            <img className='ratingstar' src={ratingstar} alt='rating-star'  />
                        </div>
                    </div>
                    <div className='d-flex mb-3' style={{ alignItems: "center" }}>
                        <img src={quote} alt='quote' />
                        <span style={{ border: "1px solid #060a4e33", height: "2px", width: "100%", marginLeft: "25px" }}></span>
                    </div>
                    <p className='mb-3'>{description1}</p>
                    <p className='mb-3'>{description3}</p>
                    <p className='mb-3'>{description2}</p>
                </div>
        </div>
    );
}

export default TestimonialCard;
