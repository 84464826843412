import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from '../../Form/Form'
import collon from '../../Assets/collan.svg'
import './Apply.css'
const Apply = () => {
    return (
        <div>
            <Row className='container mt-margin pt-pad'>
                <Col xs={12} md={8}>
                    <div>
                        <img src={collon} className='collon' alt='collon'/>
                        <h1 className='shape' style={{maxWidth:"525px",marginLeft:"50px"}}>Are you ready to shape
                            your child's future?
                            Apply now!</h1>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div>
                        <Form/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Apply