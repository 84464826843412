import React from 'react'
import './Leadership.css'
import quote from '../../Assets/quote.svg'
import leader1 from '../../Assets/leader1.png'
import leader2 from '../../Assets/leader2.png'
import leader3 from '../../Assets/leader3.png'
import leader4 from '../../Assets/leader4.png'

const Leadership = () => {
    return (
        <div className='mesh-bg'>
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>leadership</h2>
                </div>
            </div>
            <div className='position-relative custom-width'>
                <img className="leader-img" src={leader1} alt='leader' />
                <div className='custom-card leader-card'>
                <img  className="leader-img-new" src={leader1} alt='leader' />
                    <img className='mb-2'  src={quote} />
                    <p>As the flagship institution of the Baldwin Methodist Educational Society, Baldwin Girls' High School boasts a legacy spanning over 14 decades. Established in 1880, it has garnered global acclaim for its commitment to academic excellence and holistic development. Our journey, marked by milestones, charts a unique path in the landscape of education, not only in India but globally.</p>

                    <p>Rooted in traditional Indian values and history, we are dedicated to instilling 21st-century values in our students. We assure you
that Baldwin Girls' High School is the ideal environment for your child's growth and development.</p>
                    <div className='about-leader'>
                        <p>Bishop N.L. Karkare</p>
                        <p>chairman</p>
                    </div>
                </div>
            </div>

            <div className='position-relative custom-width custom-width2'>
                <img className="leader-img"  src={leader2} alt='leader' />
                <div className='custom-card leader-card2'>
                <img  className="leader-img-new" src={leader2} alt='leader' />
                    <img className='mb-2' src={quote} />
                    <p>“Education is the most powerful weapon which you can use to change the world.”– Nelson Mandela</p>

                    <p>Our commitment at Baldwin Girls’ High School is to provide a safe and intellectually challenging environment that will empower students to become innovative thinkers, creative problem solvers and inspired learners prepared to thrive in the fast changing world.</p>
                    <div className='about-leader'>
                        <p>Mrs. Asha Margaret Das </p>
                        <p>Principal</p>
                    </div>
                </div>
            </div>

            <div className='position-relative custom-width'>
                <img className="leader-img"  src={leader3} alt='leader' />
                <div className='custom-card leader-card'>
                <img  className="leader-img-new" src={leader3} alt='leader' />

                    <img className='mb-2' src={quote} />
                    <p>Education is simply the soul of a society as it passes from one generation to another." - G.K. Chesterton</p>

                    <p>In a constantly changing world, education must evolve to meet new challenges through collaboration, innovation, and growth. Since 1880, Baldwin Girls' High School has excelled in producing responsible citizens who drive societal change. The school's updated learning strategies empower students to address global issues and navigate the world beyond their immediate surroundings.
                    </p>
                    <div className='about-leader'>
                        <p>MRS. Phebe Sheela Rani</p>
                        <p>manager</p>
                    </div>
                </div>
            </div>

            <div className='position-relative  custom-width2'>
                <img  className="leader-img" src={leader4} alt='leader' />
                <div className='custom-card leader-card2'>
                    <img  className="leader-img-new" src={leader4} alt='leader' />
                    <img className='mb-2' src={quote} />
                    <p>Schooling is a shared commitment of teachers, students, and parents. At Baldwin Girls' High School, we foster a nurturing environment where learning thrives. </p>

                    <p>Our focus extends beyond academics and we strive to develop
well-rounded individuals through positive partnerships between home and school. Together, we inspire each Baldwinian to discover and unleash their potential, shaping a brighter future.</p>


                    <div className='about-leader'>
                        <p>MRS. Sujatha Catherine</p>
                        <p>vice Principal</p>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default Leadership