import React from 'react'
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';
import footerlogo from '../Assets/footer-blue.png'
import location from '../Assets/location.svg'
import mail from '../Assets/mail.svg'
import phone from '../Assets/phone.svg'
import clock from '../Assets/clock.svg'

import certificate from '../Assets/certificate.svg'
import playstore from '../Assets/playstore.svg'
import A from '../Assets/A.svg'

import admissionfooter from '../Assets/admission-footer.svg'
import studentportal from '../Assets/student-portal.svg'
import transport from '../Assets/transport.svg'



const Footer = () => {
    return (
        <div>
            <div className='footer'>
                <Container>
                    <Row className='flex-direction '>
                        <Col md={3} xs={12} className='text-center' style={{ alignSelf: "center" }}>
                            <img className='me-4 footerlogo' src={footerlogo} alt='location' />
                        </Col>
                        <Col md={4} xs={12}>
                            <p class="fw-800" >Get in touch</p>
                            <ul>
                                <li><img className='me-4' src={location} alt='location' />  # 90, Richmond Road, Bengaluru </li>
                                <li><img className='me-4' src={phone} alt='location' />  +91 91487801238 </li>
                                <li><img className='me-4' src={mail} alt='location' />  baldwingirls@gmail.com</li>
                                <li><img className='me-4' src={clock} alt='location' />  Mon-Fri  |  8:15 am - 3 pm </li>
                            </ul>
                            <p class="fw-800" >Our Affiliation</p>
                            <img className='mob-md-3' src={certificate} />

                        </Col>
                        <Col md={2} xs={12}>
                            <p class="fw-800" >Quick Links</p>
                            <ul>
                                <li><Link to={{ pathname: "/AboutUs" }} >About Us</Link></li>
                                <li><Link to={{ pathname: "/PrePrimary" }} >Academics</Link></li>
                                <li><Link to={{ pathname: "/AboutUs" }} >Admissions</Link></li>
                                <li><Link to={{ pathname: "/Campus" }} >Infrastructure</Link></li>
                                <li><Link to={{ pathname: "/Events", hash: "#Calander" }} >Calendar</Link></li>
                                <li><Link to={{ pathname: "/AboutUs" }} >Contact Us </Link></li>
                            </ul>

                        </Col>
                        <Col md={3} xs={12}>
                            <p class="fw-800">Portals</p>
                            <ul className='portal'>
                                <li><a href='https://baldwinvapsecampus.com/#/login/103' target="_blank">
                                    <div className='d-flex custom-card portallink' style={{ alignItems: "center", marginLeft: "0" }}>
                                        <img className='me-2' src={studentportal} alt='' />
                                        <p>Student portal</p>
                                    </div>
                                </a></li>
                                <li><a href='https://portal.baldwingirls.edu.in/'  target="_blank">
                                    <div className='d-flex custom-card portallink' style={{ alignItems: "center", marginLeft: "0" }}>
                                        <img className='me-2' src={admissionfooter} alt='' />
                                        <p>Admission portal</p>
                                    </div>
                                </a></li>
                                <li><a  href='https://transport.baldwingirls.edu.in/' target="_blank">
                                    <div className='d-flex custom-card portallink' style={{ alignItems: "center", marginLeft: "0" }}>
                                        <img className='me-2' src={transport} alt='' />
                                        <p>Transport portal</p>
                                    </div>
                                </a></li>

                                <li>
                                    <div className='d-flex' style={{ justifyContent: "space-evenly" }}>
                                        <a href='https://play.google.com/store/apps/details?id=com.app.bghs&hl=en' target="_blank"><img className='me-2' src={playstore} alt='' /></a>
                                        <a href='https://apps.apple.com/in/app/bghs/id6446828853' target="_blank"><img className='me-2' src={A} alt='' /></a>
                                    </div>
                                </li>
                                <li className='text-center' style={{opacity:"0.5"}}>Available on both</li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='grey-bg'>
                <div className='footerlast text-center'>
                    <p>Copyright © 2024 Baldwin Girls’ High School™. All Rights Reserved.</p>
                    {/* <div style={{ display: "flex", }}>
                        <p>Terms of Use</p>
                        <p style={{ marginLeft: "20px" }}>Privacy Policy</p>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Footer
