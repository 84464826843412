import React from 'react'
import './HouseCards.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import houseflip1 from '../../Assets/houseflip1.png'
import houseflip2 from '../../Assets/houseflip2.png'
import houseflip3 from '../../Assets/houseflip3.png'
import houseflip4 from '../../Assets/houseflip4.png'
import houseflip5 from '../../Assets/houseflip5.png'
import houseflip6 from '../../Assets/houseflip6.png'
import houseflip7 from '../../Assets/houseflip7.png'

import housecard1 from '../../Assets/housecard1.png'
import housecard2 from '../../Assets/housecard2.png'
import housecard3 from '../../Assets/housecard3.png'
import housecard4 from '../../Assets/housecard4.png'
import housecard5 from '../../Assets/housecard5.png'
import housecard6 from '../../Assets/housecard6.png'
import housecard7 from '../../Assets/housecard7.png'


const HouseCards = () => {
    return (
        <div className='housecard-margin'>

        <div className='container '>
            <div className='margin-div'>
            <div className='position-relative mb-5'>
                    <h2 className='line'>Houses</h2>
                </div>
            <Row >
                <Col  xs={6} lg={3}>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard1} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip1} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard2} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip2} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard3} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip3} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard4} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip4} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            <div className='margin-div '>
            <Row className='mb-4 housecenter'>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard5} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip5} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard6} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip6} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col  xs={6} lg={3}>
                <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={housecard7} alt='card1'/>
                            </div>
                            <div className="flip-card-back">
                                <img src={houseflip7} alt='card1'/>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>

        </div>
        </div>

    )
}

export default HouseCards