import React, { useState, useEffect ,useRef} from 'react';
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from '../Assets/logo.svg';
import './Header.css';

function Header() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const handleNavLinkClick = () => {
    setIsActive(false); // Close the navbar on link click
  };

  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsActive(false); // Close the navbar when route changes
  }, [location.pathname]);



  useEffect(() => {
    if (location.state && location.state.targetSection) {
      const targetSection = document.querySelector(location.state.targetSection);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If no target section specified, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);


  
  

  return (
    <Navbar expand="lg" className="bg-body-tertiary header">
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleNavLinkClick}>
          <img className='main-logo' src={Logo} alt='logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          onClick={handleToggleClick}
          className={isActive ? "custom-toggler active" : "custom-toggler"}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          show={isActive}
          onHide={handleNavLinkClick}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
             
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Navbar.Brand as={Link} to="/" onClick={handleNavLinkClick}>
          <img className='main-logo mob-block w-100' style={{marginBottom:"15px"}} src={Logo} alt='logo' />
        </Navbar.Brand>
            <Nav className="ms-auto text-center">
              <Nav.Link
                as={NavLink}
                exact
                to="/"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/AboutUs"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                About Us
              </Nav.Link>
              <NavDropdown title="Academics" id="collapsible-nav-dropdown">
                <NavDropdown.Item
                  as={NavLink}
                  to="/PrePrimary"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  Pre-Primary
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to="/Primary"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  Primary
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to="/MiddleSchool"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  Middle School
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to="/HighSchool"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  High School
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={NavLink}
                to={{ pathname: "/Events"}}
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                
                Events
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/CoCurricular"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                Co-Curricular
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/Campus"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                Infrastructure
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/Sports"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                Sports
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/Alumni"
                activeClassName="active"
                onClick={handleNavLinkClick}
              >
                Alumni
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
