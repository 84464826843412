import React from 'react'
import './Vision.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import vision from '../../Assets/vision.png';
const Vision = () => {
    return (
        <div className='vision-bg position-relative'>
            
                <Row>
                    <Col md={6} style={{background:"#F4F4FD"}}>
                        <div className='OV-padding'>
                            <p className='vision fw-700'>OUR VISION </p>
                            <p className='vision-content fw-600'>“To facilitate holistic learning, building character, and developing skills to 
                            meet global challenges.”</p>
                        </div>

                    </Col>
                    <Col md={6} style={{background:"#5959C5"}}>
<div className='custom-ml'>

                        <p className='vision fw-700' style={{color:"#FFF"}}>OUR MISSION</p>
                        <ul >
                        <li>BGHS strives to provide an environment conducive to creating progressive analytical thinkers. We emphasize on the physical, emotional, intellectual, social, moral, and spiritual development of each child.</li>

                        <li>BGHS believes in the potential of each child and provides varied opportunities to nurture her talent. We empower students with skills to be able leaders and team builders, enabling them to contribute in a meaningful manner to society.</li>

                        <li>Students and teachers work towards creating an atmosphere of cooperation and mutual respect for individual differences, building a peaceful and brighter world.</li>

                        </ul>
</div>
                 

                    </Col>
                </Row>
            </div>
      
    )
}
export default Vision