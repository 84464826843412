import React from 'react'
import './Infrastructure.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import lab from '../../Assets/lab.svg'
import Boardingout from '../../Assets/campus/outdoor/Boardingout.jpg'
import Garden from '../../Assets/campus/outdoor/Garden.jpg'
import Administrativeblock from '../../Assets/campus/outdoor/Administrativeblock.jpg'
import Playground from '../../Assets/campus/outdoor/Playground.JPG'
import Boarding from '../../Assets/campus/outdoor/Boarding.JPG'
import ChildrenPlayArea from '../../Assets/campus/outdoor/ChildrenPlayArea.JPG'
import ParkingArea from '../../Assets/campus/outdoor/ParkingArea.jpg'

import Auditorium from '../../Assets/campus/indoor/Auditorium.JPG'
import Boarding1 from '../../Assets/campus/indoor/Boarding.jpg'
import Classrooms from '../../Assets/campus/indoor/Classrooms.JPG'
import HostelDiningHall from '../../Assets/campus/indoor/HostelDiningHall.jpg'
import Infirmary from '../../Assets/campus/indoor/Infirmary.JPG'
import Library from '../../Assets/campus/indoor/Library.JPG'
import ScienceLab from '../../Assets/campus/indoor/ScienceLab.JPG'
const Infrastructure = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,

    };

    return (
        <div className='container mt-margin facilities-img'>
            {/* <div className='position-relative mb-5'>
                <h2 className='line'>NEWS</h2>
            </div> */}
            <Tabs>
                <TabList >
                    <Tab className='tab-label'>Indoor facilities</Tab>
                    <Tab className='tab-label'>Outdoor facilities</Tab>
                </TabList>

                <p className="mb-5"></p>

                <TabPanel>
                    <Slider {...settings}>
                    <div className='position-relative lab'>
                            <img className='w-100' src={Boarding1} alt='lab' />
                            <p>Boarding</p>
                        </div>
                        <div className='position-relative lab'>
                            <img className='w-100' src={HostelDiningHall} alt='lab' />
                            <p>Hostel Dining Hall</p>
                        </div>
                        <div className='position-relative lab'>
                            <img className='w-100' src={Auditorium} alt='lab' />
                            <p>Auditorium</p>
                        </div>
                        
                        <div className='position-relative lab'>
                            <img className='w-100' src={Classrooms} alt='lab' />
                            <p>Classrooms</p>
                        </div>

                        
                        <div className='position-relative lab'>
                            <img className='w-100' src={Infirmary} alt='lab' />
                            <p>Infirmary</p>
                        </div>
                       
                        <div className='position-relative lab'>
                            <img className='w-100' src={Library} alt='lab' />
                            <p>Library</p>
                        </div>
                        <div className='position-relative lab'>
                            <img className='w-100' src={ScienceLab} alt='lab' />
                            <p>Science Lab</p>
                        </div>
                    </Slider>
                </TabPanel>

                <TabPanel>
                    <Slider {...settings}>

                    <div className='position-relative lab'>
                            <img className='w-100' src={Boardingout} alt='lab' />
                            <p>Boarding Out</p>
                        </div>
                        <div className='position-relative lab'>
                            <img className='w-100' src={Garden} alt='lab' />
                            <p>Garden</p>
                        </div>
                        <div className='position-relative lab'>
                            <img className='w-100' src={Administrativeblock} alt='lab' />
                            <p>Administrative Block</p>
                        </div>

                    <div className='position-relative lab'>
                            <img className='w-100' src={ParkingArea} alt='lab' />
                            <p>Parking Area</p>
                        </div>
                    
                        <div className='position-relative lab'>
                            <img className='w-100' src={Playground} alt='lab' />
                            <p>Playground</p>
                        </div>
                        
                        {/* <div className='position-relative lab'>
                            <img className='w-100' src={ChildrenPlayArea} alt='lab' />
                            <p>Children Play Area</p>
                        </div> */}
                        {/* <div className='position-relative lab'>
                            <img className='w-100' src={Boarding} alt='lab' />
                            <p>Boarding</p>
                        </div> */}
                    </Slider>
                </TabPanel>

            </Tabs>
        </div>
    )
}

export default Infrastructure