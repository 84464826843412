import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const ChoiceCard = ({ choicetitle, choicedescription, number, content, sub_content, choiceimage,number2, content2, sub_content2,number3, content3, sub_content3}) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div className='school-w boxx'>
        
                
                    <Row className='mb-5 flex-direction '>
                    <Col md={6} className='mob-pe-0'>
                            <img className='w-100 h-100' src={choiceimage} alt='about school' />
                        </Col>
                        <Col md={6} className='flex-coloumn mob-5 position-relative choice' style={{ alignItems: "start"}}>
                            <p className='choicetitle'>{choicetitle}</p>
                            <p className='fw-400 choicedescription poppins'>{choicedescription}</p>
                            <div className='d-flex'>
                                    
                                <div>
                                <p className='num' data-aos="flip-left"
     data-aos-delay="1000"  data-aos-duration="2000">{number}</p>
                                </div>
                                <div>
                                <p className='fw-600 ms-3' style={{color:"#5959C5"}}>{content}</p>
                                <p className='fw-400 ms-3' style={{fontSize:"14px"}}>{sub_content}</p>
                                </div>
                            </div>
                            <div className='d-flex'>
                            <div>
                                <p className='num'data-aos="flip-left"
     data-aos-delay="1000"  data-aos-duration="2000">{number2}</p>
                                </div>
                                <div>
                                <p className='fw-600 ms-3' style={{color:"#5959C5"}}>{content2}</p>
                                <p className='fw-400 ms-3' style={{fontSize:"14px"}}>{sub_content2}</p>
                                </div>
                            </div>
                            <div className='d-flex'>
                            <div>
                                <p className='num' data-aos="flip-left"
     data-aos-delay="1000"  data-aos-duration="2000">{number3}</p>
                                </div>
                                <div>
                                <p className='fw-600 ms-3' style={{color:"#5959C5"}}>{content3}</p>
                                <p className='fw-400 ms-3' style={{fontSize:"14px"}}>{sub_content3}</p>
                                </div>
                            </div>
                        </Col>                        
                    </Row>
               
        
        </div>
    )
}

export default ChoiceCard