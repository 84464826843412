import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Programme = () => {
    return (
        <div className='container mt-margin' >
            <div className='position-relative mb-5'>
                <h2 className='line'>The High School Programme</h2>
            </div>
                <Row className='mb-5 flex-direction programme primaryrow'>
                    <Col md={4} xs={6}>
                        <div className="setscard text-center">
                          <h3>Standard 9</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity'>Apply now</a>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className="setscard text-center">
                          <h3>Standard 10</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity'>Apply now</a>
                        </div>
                    </Col>
                   
                    
                </Row>
        </div>
    )
}

export default Programme