import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion_ACR from '../Accordion/Accordion';

const CurriculumCard = ({ curriculumImage, curriculumTitle, curriculumSubTitle, kite2 }) => {
    const sections = [
        
        {
            label: 'Subjects',
            subcontent: 'PRE-KG, LKG, UKG',
            content: 'English (Oral and Written)',
            content2: 'Number Work (Oral and Written)',
            content3: ' EVS (Oral and Written)',
            content4:'Rhymes ',
            content5:'Conversation',
            content6:'Art and Craft ',
            content7:'Computers for UKG',

                                     
            label: 'Subjects',
            subcontent2: 'STANDARD I & II',
            contentt: ' English Language',
            content22: 'English Literature',
            content23: ' Kannada',
            content24:'Hindi ',
            content25:'Mathematics',
            content26:'E.V.S ',
            content27:'Computers',
        },
       
    ];

    return (
        <div className='position-relative school-w'>
            <img className='w-100 kite2' src={kite2} alt='kite' />

            <Row>
                <Col xs={12} md={4} className='mob-p-0'>
                    <img className='w-100' src={curriculumImage} alt='school girl' />
                </Col>
                <Col xs={12} md={8} style={{ alignSelf: "center" }}>
                    <div className='container' >
                        <div className='position-relative mb-5'>
                            <h2 className='line'>{curriculumTitle}</h2>
                        </div>
                        <p className='mb-4 fw-600' style={{ color: "#2D306F" }}>{curriculumSubTitle}</p>
                    </div>
                    <div className='accor-width' >
                        <Accordion_ACR sections={sections} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default CurriculumCard;
