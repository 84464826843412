import React from 'react'
import './History.css'
import historyImg from '../../Assets/history-img.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const history = [
    {
        schooltitle:"A Gist of school history",        
        schooldescription: "The school that was started in 1880 at #2 Alexandra Street with the aim to provide schooling for English-speaking Methodist children, went through much hardship during the early years, plagued with a paucity of funds and dwindling strength of children. It was on the brink of closure when Mr. John Baldwin gifted a sum of thousand dollars to acquire land and buildings and keep the school from closing.",
        schooldescription2: "Since then, the school has seen steady progress in student strength, which stands at five thousand six hundred today, an exponential expansion in terms of infrastructure, and dedicated staff that help the school move forward, imparting quality Christian education." , 
        schooldescription3:"In retrospect, the humble beginning of the Institution in 1880 with a few students and minimal facilities stands in stark contrast to its present stature and dignity, having a global perspective and meeting the demands of specialization in the methods of acquiring knowledge, with a view to improving the standard and quality of life, thus redefining the role of education.",
        schooldescription4:"Today, the institution is a brilliant canvas of many shades and hues. It is certainly not the monotonous regularity of a stagnant social tradition but a continuous and uninterrupted move towards a dynamic and vibrant vision, which keeps us alive with new ideas, new avenues, and new in-roads.",
        schooldescription5:"The Institution is now a symbol of the triumph of tradition and individual talent, the fruit of “Loyalty and service, Righteousness, and Truth” emanating from the warm, God-fearing heart of John Baldwin and other noble souls that led to its phenomenal growth. Today it stands as the tallest landmark of quality education in the city.",
        image: historyImg,       
    },
    
];

const History = () => {
  return (
    <div className='History histry'>
        {history.map((history, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={history.schooltitle}
                    schooldescription={history.schooldescription}
                    schooldescription2={history.schooldescription2}
                    schooldescription3={history.schooldescription3}
                    schooldescription4={history.schooldescription4}
                    schooldescription5={history.schooldescription5}
                    image={history.image}
                    />
                ))}
    </div>
  )
}

export default History