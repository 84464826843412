import React from 'react'
import './Transportation.css'
import car from '../../Assets/car.png'

const Transportation = () => {
    return (
        <div className='mt-margin'>
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>TRANSPORTATION</h2>
                </div>
            </div>
            <div className='carbg'>
                <img  src={car} className='w-100 car' alt=''/>
                <div className='login-placement'>
                    <h3 className='mb-3'>BGHS Transport Module </h3>
                    <a href='https://transport.baldwingirls.edu.in/' target='_blank' style={{padding:"10px 30px",borderRadius:"4px",fontWeight:"700"}} className='custom-btn'>Login</a>
                </div>
            </div>
        </div>
    )
}

export default Transportation