import React from 'react'
import '../../PrePrimaryComponents/Choice/Choice.css'
import choice_img from '../../Assets/middleoption.png'
import ChoiceCard from '../../PrePrimaryComponents/ChoiceCard/ChoiceCard';

const choice = [
    {
        choicetitle:"Why are we the best choice for your child?",        
        choicedescription: "Our Class 6 to 8 Programme integrates concepts with real-world contexts, enabling students to explore overarching Principles that transcend individual subjects and nurture a holistic perspective on the world.",
        number: "01" , 
        content: "Transdisciplinary Learning",
        sub_content:"Our middle school program offers a transdisciplinary approach, integrating knowledge and skills from various disciplines to foster interdisciplinary understanding and critical thinking.",

        number2: "02" , 
        content2: "Leadership Development",
        sub_content2:"Students are provided with opportunities for leadership development, empowering them to take on roles of responsibility and make positive contributions to their school and community.",


        number3: "03" , 
        content3: "Technological Integration",
        sub_content3:"We leverage technology to enhance learning experiences, providing students with access to cutting-edge tools and resources to support their academic and personal growth.",

        choiceimage:choice_img,    
    },    
];
const Choice = () => {
  return (
    <div>
        {choice.map((choice, index) => (
                    <ChoiceCard
                    key={index} 
                    choicetitle={choice.choicetitle}
                    choicedescription={choice.choicedescription}
                    number={choice.number}
                    content={choice.content}
                    sub_content={choice.sub_content}
                    number2={choice.number2}
                    content2={choice.content2}
                    sub_content2={choice.sub_content2}
                    number3={choice.number3}
                    content3={choice.content3}
                    sub_content3={choice.sub_content3}
                    choiceimage={choice.choiceimage}
                    />
                ))}
    </div>
  )
}
export default Choice