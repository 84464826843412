import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import tq from '../Components/Assets/tq.png'
const Thankyou = () => {
    return (
        <div style={{padding:"70px 0"}}>
            <Row>
                <Col xs={12} lg={6}>
                    <div>
                        <img className='w-100' src={tq} alt='tq-img' />
                    </div>
                </Col>
                <Col xs={12} lg={6} style={{alignSelf:"center",marginTop:"20px"}}>
                    <div style={{maxWidth:"300px",margin:"0 auto"}}>
                        <h2 style={{ color: "#353597", fontWeight: "700", fontSize: '40px',textAlign:"center" }}>Thank you!</h2>
                        <p style={{ color: "#2D306F", padding: "35px 0",fontWeight:"600",fontSize:"19px" }}>We have received your message!
                            Our team will review the information
                            and will get back to you soon.</p>
                        <Link to={{ pathname: "/", hash: "#ACADEMY", state: { targetSection: "#ACADEMY" } }} className='fw-800 card text-center' style={{ color: "#5959C5",maxWidth:"270px",margin:"0 auto" }}>Back to home page</Link>

                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Thankyou;
