import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import rectangle from '../../Assets/Rectangle.svg';
const SchoolCard = ({ schooltitle, schooldescription, schooldescription2,schooldescription3,schooldescription4,schooldescription5, image }) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div className='aboutmt-margin mt-margin position-relative school-w'>
            <img className='rect2' src={rectangle} alt='' />          

                <Row className='flex-direction welcome welcomenewp'>
                    <Col md={7} className='flex-coloumn mob-5 position-relative school-left' style={{ alignItems: "start" }}>
                        <div className='position-relative'>
                            <h2 data-aos="fade-right" data-aos-delay="1000" data-aos-duration="2000" className='line'>{schooltitle}</h2>
                        </div>
                        <p>{schooldescription}</p>
                        <p>{schooldescription2}</p>
                        <p className='DailySchedule'>{schooldescription3}</p>
                        <p>{schooldescription4}</p>
                        <p>{schooldescription5}</p>

                    </Col>
                    <Col md={5}>
                        <div className='position-relative'>
                            <div className='rectangle'>
                                <img className='w-100' src={image} alt='about school' />
                            </div>
                        </div>
                    </Col>
                </Row>
        </div>
    )
}

export default SchoolCard