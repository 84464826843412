import React from 'react'
// import './History.css'
import historyImg from '../../Assets/cocurriculumm.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const history = [
    {
        schooltitle:"Explore Co-Curricular Activities",        
        schooldescription: "Baldwin Girls’ High School provides ample scope for the development of co-curricular activities. A lot of emphasis is laid on the complete development of the body, mind, and spirit. ",
        schooldescription2: "The girls are exposed to various kinds of activities. A list of activities are provided by the school from which the girls can choose according to their interests and choices." , 
        image: historyImg,     
        
        


    },
    
];
const Ceremony = () => {
  return (
    <div className='History'>
        {history.map((history, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={history.schooltitle}
                    schooldescription={history.schooldescription}
                    schooldescription2={history.schooldescription2}
                    image={history.image}
                    />
                ))}
    </div>
  )
}

export default Ceremony