import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Sets.css';
import developnment from '../Assets/developnment.svg';
import robotics from '../Assets/robotics.svg'
import AI from '../Assets/AI.svg'
import counsling from '../Assets/counsling.svg'
import boarding from '../Assets/boarding.svg'

const Sets = () => {  
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='container mt-marginset'>
            <div className='position-relative mb-5'>
                <h2 className='line'>What sets us apart ? </h2>
            </div>

          
                <Row className='mb-5 flex-direction welcome setss'>
                    <Col md xs={6}>
                        <div className="setscard" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                            <img src={developnment} alt="sets" />
                            <p>DEVELOPMENT PROGRAMME</p>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                            <img src={robotics} alt="sets" />
                            <p>ROBOTICS</p>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                            <img src={AI} alt="sets" />
                            <p>ARTIFICIAL
                                INTELLIGENCE</p>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                            <img src={counsling}  alt="sets"/>
                            <p>COUNSELLING &
                            CAREER GUIDANCE</p>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                            <img src={boarding} alt="sets" />
                            <p>BOARDING SCHOOL</p>
                        </div>
                    </Col>
                </Row>
                


        </div>
    )
}

export default Sets