// AdmissionCard.js
import React, { useState } from 'react';
import PopupModal from '../PopupModal';
import "./AdmissionCard.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import newimg from '../Assets/new-img.svg'
const AdmissionCard = ({ emoji, title, description, rel_btn, rel_btn_href }) => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Row >
                <Col xs={12} md={8}> 
                    <img className='w-100  desk-block height450'  src={newimg} alt='newimg' />
                </Col>
                <Col xs={12} md={4} style={{alignSelf:"center"}}>
                    <div className='custom-card flex-coloumn admission'>
                        <img src={emoji} alt='emoji' />
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <button style={{ fontSize: "16px" }} href={rel_btn_href} onClick={handleShow} className='custom-btn'>{rel_btn}</button>
                    </div>
                </Col>

            </Row>

            <PopupModal show={showModal} handleClose={handleClose} />
        </div>
    );
}

export default AdmissionCard;
