import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Prayer.css';
const Prayer = () => {
    return (
        <div className='position-relative mt-up-100'>
            <Row >
                <Col md={6} xs={12} className='grey-bg2'>
                    <div className=' prayer '>
                        <h2 className='mb-4'>SCHOOL HYMN</h2>
                        <p>We build our school on Thee, O Lord; To Thee we bring our common need; The loving heart, the helpful word, The tender thought, the kindly deed. With these, we pray Thy Spirit may Enrich and bless our School alway.</p>

                        <p>We work together in Thy sight, We live together in Thy love; Guide Thou our faltering steps aright, And lift our thoughts to heaven above. Dear Lord, we pray Thy Spirit may Be present in our School alway.</p>

                        <p>Hold Thou each hand to keep it just, Touch Thou our lips and make them pure; If Thou art with us, Lord, we must Be faithful friends and comrades sure. Dear Lord, we pray Thy Spirit may Be present in our School alway.</p>

                        <p>We change, but Thou art still the same; The same good Master, teacher, friend. We change, but Lord, we bear Thy Name, To journey with it to the end. And so we pray Thy Spirit may Be present in our School alway.</p>

                            <p style={{ textAlign: "right" }}>-S. W. Mayer</p>
                    </div>
                </Col>
                <Col md={6} xs={12} className='grey-bg2'>
                    <div className=' prayer ppprayer'>
                        <h2 className='mb-4'>THE LORD'S PRAYER</h2>
                        <p>Our Father, who art in heaven, hallowed be thy name;<br/>
                            thy Kingdom come; thy will be done;<br/>
                            on earth as it is in heaven.</p>

                        <p>Give us this day our daily bread.<br/>
                            And forgive us our trespasses,<br/>
                            as we forgive those who trespass against us.<br/>
                            And lead us not into temptation;<br/>
                            but deliver us from evil.</p>

                        <p>For thine is the kingdom, the power and the glory, for<br/>
                            ever and ever.</p>

                        <p>Amen.</p>

                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Prayer