// PopupModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from '../Components/Form/Form'; // Corrected casing for Form component

const PopupModal = ({ handleClose, show }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body >
                <Modal.Header closeButton className='mobcross'></Modal.Header>
                <div style={{marginTop:"50px"}}>
                <Form /> 
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PopupModal;
