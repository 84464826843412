import React from 'react'
import pschool from '../../Assets/primaryschool.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const school = [
    {
        schooltitle:"Primary school",        
        schooldescription: "Our primary programme nurtures qualities like integrity, knowledge, and reflection, fostering a culture of excellence. The primary wing consists of Standard III, IV, and V.",
        
        schooldescription2: "Various subjects are offered along with a myriad of co-curricular activities that ignite the discovery, interests, and talents of students. The school offers an inclusive curriculum to address the needs of different kinds of learners. The teachers help the students by providing them with an interactive teaching method  with a blend of the latest technology." , 

        schooldescription3:"Daily Schedule",
        schooldescription4:"Standard III, IV, V timings are from 8:15 am to 2:30 pm",
        
        image: pschool,

        


       
    },
    
];
const School = () => {
  return (
    <div className="schedulecolor">
        {school.map((school, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={school.schooltitle}
                    schooldescription={school.schooldescription}
                    schooldescription2={school.schooldescription2}
                    schooldescription3={school.schooldescription3}
                    schooldescription4={school.schooldescription4}
                    image={school.image}
                    />
                ))}
    </div>
  )
}

export default School