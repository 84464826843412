import React from 'react';
import Slider from 'react-slick';
import '../../Testimonials/Testimonials.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import emoji from '../../Assets/emoji4.png';
import emoji2 from '../../Assets/emoji2.svg';
import emoji3 from '../../Assets/emoji3.svg';
import wave from '../../Assets/wave.svg';
import kite from '../../Assets/kite.svg';

const Testimonials = () => {
    const testimonials = [
        {
            name: "Dr Vidya S",
            role: "Parent",
            emoji: emoji,
            description1: " “Our two years with BGHS have been joyful. Mrs. Celeena Jessy and Mrs. Preeti Williams helped my daughter’s transition smoothly. Mrs. Surashmi Joseph groomed her confidence. ",
            description2: "The school balances academics with events like 'Rhyme Time,' 'Creative Fair,' and 'Sports Day.' The kind office and bus staff have brightened our days. My daughter will cherish her BGHS memories.”"        


        },
        {
            name: "Dr Santoshini Manoj",
            role: "Parent",
            emoji: emoji2,
            description1: "“Like any other parents, we would like to give the best to my daughter.... So, we have rightly chosen BALDWIN GIRLS’ HIGH SCHOOL.",
            description2: "I am thankful to each and everyone from BGHS for catalysing our DREAMS.”",
            description3:"Compassionate, loving, happy environment makes learning a happy experience for my daughter.",
        },
        {
            name: "Asha. R",
            role: "Parent",
            emoji: emoji3,
            description1: " “As the academic year ends, I thank the pre-primary department for nurturing my child. Mrs. Preethi Williams and her team are dedicated. Events like Rhyme Time and Sports Day are well-organized.  ",
            description2: "  Mrs. Catherine ensures children perform well and teaches important life lessons. Baldwin Girls' High School balances academics as well as extracurricular well.”"        
           

          
            
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='testimonials-container position-relative mt-margin parentallign'>
            <img className='kite' src={kite} alt='kite' />
            <img className='wave' src={wave} alt='wave' />
            <div className='container'>
                <div className='header position-relative mb-5'>
                    <h2 className='line'>Testimonials</h2>
                </div>
                <p className='subtitle mb-4 fw-600' style={{color:"#2D306F"}}>What Parents have to say about BGHS</p>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={`testimonial-${index}`}
                            name={testimonial.name}
                            role={testimonial.role}
                            emoji={testimonial.emoji}
                            description1={testimonial.description1}
                            description2={testimonial.description2}
                            description3={testimonial.description3}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;
