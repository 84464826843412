import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion_ACR from '../../PrePrimaryComponents/Accordion/Accordion'

const CurriculumCard = ({ curriculumImage,curriculumTitle,curriculumSubTitle,kite2 }) => {
    const sections = [
        {
            label: 'Subjects',
            subcontent: 'STD III, IV & V',
            content: 'English Language',
            content2: 'English Literature',
            content3: 'Hindi',
            content4:'Kannada ',
            content5:'Social Studies',
                                        
            label: 'Subjects', 
            subcontentt: '',           
            contentt: ' Mathematics',
            content22: 'General Science',
            content23: ' Computers',
            content24:'Art & Craft ',
            content25:'Value Education',        
        },
    ];
    return (
        <div className='position-relative school-w'>
            <img className='w-100 kite2' src={kite2} alt='kite' />
            <Row>
                <Col xs={12} md={4} className='mob-p-0'>
                    <img className='w-100' src={curriculumImage} alt='school girl' />
                </Col>
                <Col xs={12} md={8} style={{ alignSelf: "center" }}>
                    <div className='container' >
                        <div className='position-relative mb-5'>
                            <h2 className='line'>{curriculumTitle}</h2>
                        </div>
                        <p className='mb-4 fw-600' style={{color:"#2D306F"}}>{curriculumSubTitle}</p>
                    </div>
                    <div className='accor-width' >
                        <Accordion_ACR sections={sections} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default CurriculumCard;
