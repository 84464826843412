import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BannerCard = ({ bannerImage,bannerImagemob, bannerHeading, bannerDescription }) => {
    return (
        <div className='banner-bg'>
            <div>          
           
                <Row>
                    <Col xs={12} md={12} className='mob-p-0'>
                        <img className='w-100 desk-block' src={bannerImage} alt='school girl' />
                        <img className='w-100 mob-block' src={bannerImagemob} alt='school girl' />
                    </Col>
                    {/* <Col xs={12} md={4} style={{alignSelf:"center"}}>
                        <div className='flex-coloumn bannerstyle'>
                            <h3>{bannerHeading}</h3>
                            <p>{bannerDescription}</p>
                        </div>
                    </Col> */}
                </Row>
           
            </div>
        </div>
    );
}

export default BannerCard;
