import React from 'react'
import Eventsbanner from '../Components/CampusComponents/Eventsbanner/Eventsbanner'
import EventsAdmission from '../Components/CampusComponents/EventsAdmission/EventsAdmission'
import Infrastructure from '../Components/CampusComponents/Infrastructure/Infrastructure'
import EventsInformation from '../Components/CampusComponents/EventsInformation/EventsInformation'
import EventsOtherSection from '../Components/CampusComponents/EventsOtherSection/EventsOtherSection'

const Campus = () => {
  return (
    <div style={{background:"#FFF"}}>
        <Eventsbanner/> 
        <Infrastructure/>
        <EventsAdmission/> 
        <EventsInformation/> 
        <EventsOtherSection/>        
    </div>
  )
}

export default Campus