import React, { useRef, useEffect } from 'react';
import '../../Infrastructure/Infrastructure.css';
import '../../HighSchoolComponents/Gallery/Gallery.css';
import './EventsGallery.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import art1 from '../../Assets/co-curricculum/art/art1.JPG';
import art2 from '../../Assets/co-curricculum/art/art2.JPG';
import art3 from '../../Assets/co-curricculum/art/art3.JPG';
import art4 from '../../Assets/co-curricculum/art/art4.JPG';
import art5 from '../../Assets/co-curricculum/art/art5.JPG';
import art6 from '../../Assets/co-curricculum/art/art6.JPG';
import art7 from '../../Assets/co-curricculum/art/art7.JPG';
import art8 from '../../Assets/co-curricculum/art/art8.JPG';
import art9 from '../../Assets/co-curricculum/art/art9.JPG';
import art10 from '../../Assets/co-curricculum/art/art10.JPG';
import art11 from '../../Assets/co-curricculum/art/art11.JPG';
import art12 from '../../Assets/co-curricculum/art/art12.JPG';
import art13 from '../../Assets/co-curricculum/art/art13.JPG';
import art14 from '../../Assets/co-curricculum/art/art14.JPG';
import art15 from '../../Assets/co-curricculum/art/art15.JPG';
import art16 from '../../Assets/co-curricculum/art/art16.JPG';
import art17 from '../../Assets/co-curricculum/art/art17.JPG';
import art18 from '../../Assets/co-curricculum/art/art18.JPG';
import art19 from '../../Assets/co-curricculum/art/art19.JPG';

import carft1 from '../../Assets/co-curricculum/craftclass/ceaft1.jpg';
import carft2 from '../../Assets/co-curricculum/craftclass/ceaft2.jpg';
import carft3 from '../../Assets/co-curricculum/craftclass/ceaft3.jpg';
import carft4 from '../../Assets/co-curricculum/craftclass/ceaft4.jpg';

import fair1 from '../../Assets/co-curricculum/creativityfair/fair1.JPG';
import fair2 from '../../Assets/co-curricculum/creativityfair/fair2.JPG';
import fair3 from '../../Assets/co-curricculum/creativityfair/fair3.JPG';
import fair4 from '../../Assets/co-curricculum/creativityfair/fair4.JPG';
import fair5 from '../../Assets/co-curricculum/creativityfair/fair5.JPG';
import fair6 from '../../Assets/co-curricculum/creativityfair/fair6.JPG';
import fair7 from '../../Assets/co-curricculum/creativityfair/fair7.JPG';
import fair8 from '../../Assets/co-curricculum/creativityfair/fair8.JPG';
import fair9 from '../../Assets/co-curricculum/creativityfair/fair9.JPG';
import fair10 from '../../Assets/co-curricculum/creativityfair/fair10.JPG';
import fair11 from '../../Assets/co-curricculum/creativityfair/fair11.JPG';
import fair12 from '../../Assets/co-curricculum/creativityfair/fair12.JPG';
import fair13 from '../../Assets/co-curricculum/creativityfair/fair13.JPG';
import fair14 from '../../Assets/co-curricculum/creativityfair/fair14.JPG';
import fair15 from '../../Assets/co-curricculum/creativityfair/fair15.JPG';
import fair16 from '../../Assets/co-curricculum/creativityfair/fair16.JPG';
import fair17 from '../../Assets/co-curricculum/creativityfair/fair17.JPG';


import dance1 from '../../Assets/co-curricculum/dance/dance1.JPG';
import dance2 from '../../Assets/co-curricculum/dance/dance2.JPG';
import dance3 from '../../Assets/co-curricculum/dance/dance3.JPG';
import dance4 from '../../Assets/co-curricculum/dance/dance4.JPG';
import dance5 from '../../Assets/co-curricculum/dance/dance5.JPG';
import dance6 from '../../Assets/co-curricculum/dance/dance6.JPG';
import dance7 from '../../Assets/co-curricculum/dance/dance7.JPG';
import dance8 from '../../Assets/co-curricculum/dance/dance8.JPG';
import dance9 from '../../Assets/co-curricculum/dance/dance9.JPG';
import dance10 from '../../Assets/co-curricculum/dance/dance10.JPG';
import dance11 from '../../Assets/co-curricculum/dance/dance11.JPG';
import dance12 from '../../Assets/co-curricculum/dance/dance12.JPG';

import music1 from '../../Assets/co-curricculum/music/music1.JPG';
import music2 from '../../Assets/co-curricculum/music/music2.JPG';
import music3 from '../../Assets/co-curricculum/music/music3.JPG';
import music4 from '../../Assets/co-curricculum/music/music4.JPG';
import music5 from '../../Assets/co-curricculum/music/music5.JPG';

import ncc1 from '../../Assets/co-curricculum/ncc/ncc1.JPG';

import ncc4 from '../../Assets/co-curricculum/ncc/ncc4.JPG';
import ncc5 from '../../Assets/co-curricculum/ncc/ncc5.JPG';
import ncc6 from '../../Assets/co-curricculum/ncc/ncc6.JPG';
import ncc7 from '../../Assets/co-curricculum/ncc/ncc7.JPG';
import ncc8 from '../../Assets/co-curricculum/ncc/ncc8.JPG';
import ncc9 from '../../Assets/co-curricculum/ncc/ncc9.JPG';
import ncc10 from '../../Assets/co-curricculum/ncc/ncc10.JPG';

import quiz from '../../Assets/co-curricculum/quizz/quiz1.JPG';
import quiz2 from '../../Assets/co-curricculum/quizz/quiz2.JPG';
import quiz3 from '../../Assets/co-curricculum/quizz/quiz3.JPG';
import quiz4 from '../../Assets/co-curricculum/quizz/quiz4.JPG';
import quiz5 from '../../Assets/co-curricculum/quizz/quiz5.JPG';
import quiz6 from '../../Assets/co-curricculum/quizz/quiz6.JPG';
import quiz8 from '../../Assets/co-curricculum/quizz/quiz8.JPG';
import quiz9 from '../../Assets/co-curricculum/quizz/quiz9.JPG';
import quiz10 from '../../Assets/co-curricculum/quizz/quiz10.JPG';
import quiz11 from '../../Assets/co-curricculum/quizz/quiz11.JPG';


import rhyme1 from '../../Assets/co-curricculum/rhymetime/rhyme1.jpeg';
import rhyme2 from '../../Assets/co-curricculum/rhymetime/rhyme2.jpeg';
import rhyme3 from '../../Assets/co-curricculum/rhymetime/rhyme3.jpeg';
import rhyme4 from '../../Assets/co-curricculum/rhymetime/rhyme4.jpeg';
import rhyme5 from '../../Assets/co-curricculum/rhymetime/rhyme5.jpeg';
import rhyme6 from '../../Assets/co-curricculum/rhymetime/rhyme6.jpeg';
import rhyme7 from '../../Assets/co-curricculum/rhymetime/rhyme7.jpeg';





const EventsGallery = () => {
    const mainSlider = useRef(null);
    const navSlider = useRef(null);

    useEffect(() => {
        if (mainSlider.current && navSlider.current) {
            const mainSlick = mainSlider.current.innerSlider;
            const navSlick = navSlider.current.innerSlider;
            if (mainSlick && navSlick) {
                mainSlick.slickGoTo(0);
                navSlick.slickGoTo(0);
            }
        }
    }, []);

    const handleNavClick = (index) => {
        if (mainSlider.current) {
            mainSlider.current.slickGoTo(index);
        }
    };

    const mainSettings = {
        dots: false,
        infinite: true,
        // speed: 500, autoplay: true,
        // autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: navSlider.current,
        afterChange: (index) => {
            if (navSlider.current) {
                navSlider.current.slickGoTo(index);
            }
        }
    };
    const navSettings = {
        dots: false,
        infinite: true,
        // speed: 500, autoplay: true,
        // autoplaySpeed: 3000, 
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: mainSlider.current,
       
        responsive: [
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
        beforeChange: (current, next) => {
            if (mainSlider.current) {
                mainSlider.current.slickGoTo(next);
            }
        }
    };


    const images = {
        Dance: [
            dance4,
            dance1,
            dance2,
            dance3,
            dance5,
            dance6,
            dance7,
            dance8,
            dance9,
            dance10,
            dance11,
            dance12
        ],
        ART: [
            art1,
            art2,
            art3,
            art4,
            art5,
            art6,
            art7,
            art8,
            art9,
            art10,
            art11,
            art12,
            art13,
            art14,
            art15,
            art16,
            art17,
            art18,
            art19,
        ],
        CraftClasses: [carft1,
            carft2,
            carft3,
            carft4],
        CreativityFair: [
            fair1,
            fair2,
            fair3,
            fair4,
            fair5,
            fair6,
            fair7,
            fair8,
            fair9,
            fair10,
            fair11,
            fair12,
            fair13,
            fair14,
            fair15,
            fair16,
            fair17,
        ],
      
        Music: [
            music1,
music2,
music3,
music4,
music5,
        ],
        NCC: [
            ncc1,

ncc4,
ncc5,
ncc6,
ncc7,
ncc8,
ncc9,
ncc10,
        ],
        Quiz: [
          quiz,
 quiz2,
 quiz3,
 quiz4,
 quiz5,
 quiz6,
 quiz8,
 quiz9,
quiz10,
quiz11,

        ],
        RhymeTimes: [rhyme1,
            rhyme2,
            rhyme3,
            rhyme4,
            rhyme5,
            rhyme6,
            rhyme7,]
    };

    const renderSlider = (category) => (
        <div className='Gallery'>
            <div className='container'>
                <Slider className='mt-reverse2' {...mainSettings} ref={mainSlider}>
                    {images[category].map((image, idx) => (
                        <div key={idx}>
                            <div className="white-bg">
                                <img className='gallery' src={image} alt={`gallery ${idx}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {/* <Slider {...navSettings} ref={navSlider}>
                {images[category].map((image, idx) => (
                    <div key={idx} onClick={() => handleNavClick(idx)}>
                        <img className='gallery min-img ' src={image} alt={`gallery ${idx}`} />
                    </div>
                ))}
            </Slider> */}
        </div>
    );

    return (
        <div className='position-relative aboutGallery'>
            <div className='blue-content blue-bg'>
                <div className='container'>
                    <h3>GALLERY</h3>
                </div>
            </div>
            <div className='container'>
                <Tabs>
                    <TabList className="tab-margin">
                        <Tab className='tab-label'>Dance</Tab>
                        <Tab className='tab-label'>ART </Tab>
                        <Tab className='tab-label'>Craft Classes</Tab>
                        <Tab className='tab-label'>Creativity Fair</Tab>
                        <Tab className='tab-label'>Music</Tab>
                        <Tab className='tab-label'>NCC</Tab>
                        <Tab className='tab-label'>Quiz</Tab>
                        <Tab className='tab-label'>Rhyme Times</Tab>
                    </TabList>

                    <TabPanel>
                    {renderSlider('Dance')}
                 
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('CraftClasses')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('CreativityFair')}
                    </TabPanel>
                    <TabPanel>
                    {renderSlider('ART')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('Music')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('NCC')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('Quiz')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('RhymeTimes')}
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default EventsGallery;
