import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick';
import './News.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import mesh from '../Assets/mesh.svg'
import bulb from '../Assets/bulb.svg'
import news from '../Assets/news.png'
import news2 from '../Assets/news2.png'

const News = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return ( 
        <div className='position-relative mesh-news-bg  '>
            {/* <img className='mesh' src={mesh} alt='mesh' /> */}
            <img className='bulb' src={bulb} alt='flask' />
            <div className='container Announcement'>
                <div className='position-relative mb-5'>
                    <h2   className='line'>NEWS</h2>
                </div>
                <h4   className='mb-4 fw-600' style={{color:"#2D306F"}}>What’s New?</h4>
                <Slider {...settings} >
                 <div className='position-relative newsimg' >
                        <img className='w-100' src={news} alt='board' />
                    </div>
                    <div className='position-relative newsimg'>
                        <img className='w-100' src={news2} alt='board' />
                    </div>
                    {/* <div className='position-relative'>
                        <div className='board-small-container'>
                            <img className='board-small' src={news2} alt='board' />
                        </div>
                    </div> */}
                  
                </Slider>
            </div>
        </div>
    )
}

export default News
