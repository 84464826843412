import React from 'react'
import './Curriculum.css'
import CurriculumCard from '../CurriculumCard/CurriculumCard'
import curriculum_img from '../../Assets/curriculuamPrimary.png';
import kite2 from '../../Assets/kite2.svg';

const curriculum = [
    {
        curriculumTitle: "The Primary Curriculum Framework",
        curriculumSubTitle: "The Primary Programme encompasses a diverse range of subjects fostering comprehensive student development.",
        curriculumImage:curriculum_img,
       kite2:kite2,
       

    },   
];
const Curriculum = () => {
    return (
        <div className='Curriculum mt-margin'>
                {curriculum.map((curriculum, index) => (
                    <CurriculumCard
                    key={index} 
                    curriculumTitle={curriculum.curriculumTitle}
                    curriculumSubTitle={curriculum.curriculumSubTitle}
                    curriculumImage={curriculum.curriculumImage}
                    kite2={curriculum.kite2}
                   
                    />
                ))}
        </div>
    )
}

export default Curriculum