import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Academy.css'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import schoolgirl1 from '../Assets/schoolgirl1.png'
import schoolgirl2 from '../Assets/schoolgirl2.png'
import schoolgirl3 from '../Assets/schoolgirl3.png'
import schoolgirl4 from '../Assets/schoolgirl4.png'
import rightdesign from '../Assets/right-design.svg'
import leftdesign from '../Assets/left-design.svg'
import middesign from '../Assets/mid-design.svg'
const Academy = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='position-relative mb-5' id="ACADEMY">
            <img className='leftdesign' src={rightdesign} alt='design' />
            <img className='rightdesign' src={leftdesign} alt='design' />
            <img className='middesign' src={middesign} alt='design' />
            <div className='blue-bg'>
                <div className='container blue-content'>
                    <h1 >ACADEMICS</h1>
                    {/* <p className='light-blue2'>Our curriculum instills a passion for continuous learning, preparing students not <span> only for university but also for the challenges they will face throughout their lives</span></p> */}
                </div>
            </div>
            <div className="mob-container">
                <Container>
                    <Row className='mt-reverse3'>
                        <Col xs={6} md={3}>
                            <div className='card flex-coloumn'>
                                <img src={schoolgirl1} alt='school girl' />
                                <div className='card2 flex-coloumn'>
                                    <h3>Pre-Primary</h3>
                                    <p>PRE-KG to Standard 2</p>
                                </div>
                                <Link to={{ pathname: "/PrePrimary", }} className='custom-btn'>Explore Courses</Link>
                            </div>
                        </Col>
                        <Col xs={6} md={3}>
                            <div className='card flex-coloumn'>
                                <img src={schoolgirl2} alt='school girl' />
                                <div className='card2 flex-coloumn'>
                                    <h3>Primary</h3>
                                    <p>Standard 3 to Standard 5</p>
                                </div>
                                <Link to={{ pathname: "/Primary", }} className='custom-btn'>Explore Courses</Link>
                            </div>
                        </Col>
                        <Col xs={6} md={3}>
                            <div className='card flex-coloumn'>
                                <img src={schoolgirl3} alt='school girl' />
                                <div className='card2 flex-coloumn'>
                                    <h3> Middle School</h3>
                                    <p>Standard 6 to  Standard 8
</p>
                                </div>
                                <Link to={{ pathname: "/MiddleSchool", }} className='custom-btn'>Explore Courses</Link>
                            </div>
                        </Col>
                        <Col xs={6} md={3} style={{ alignSelf: "center" }}>
                            <div className='card flex-coloumn'>
                                <img src={schoolgirl4} alt='school girl' />
                                <div className='card2 flex-coloumn'>
                                    <h3> High School</h3>
                                    <p>Standard 9 to Standard 10</p>
                                </div>
                                <Link to={{ pathname: "/HighSchool", }} className='custom-btn'>Explore Courses</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default Academy