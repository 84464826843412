import React from 'react';
import './Initiative.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import search from '../../Assets/search.png';
import message from '../../Assets/message.png'
import increase from '../../Assets/increase.png'
const Initiative = () => {
    return (
        <div className='mt-margin'>
            <div className='container'>
            <div className='position-relative mb-5'>
                <h2 className='line'>Alumni Engagement Initiative</h2>
            </div>
            <p style={{marginBottom:"30px",color:"#2D306F"}}>A platform to connect, learn, and grow with our alumni.</p>
                <Row>
                    <Col xs={12} lg={4} style={{ alignSelf: "center", marginTop: "20px" }}>
                        <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <img className='mini' src={message} alt='tq-img' />
                            <h4 style={{color:"#5959C5",textAlign:"center"}}>CONNECT</h4>
                            <p style={{color:"#000000",opacity:"0.6"}}>Connect with alumni from around the world.</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} style={{ alignSelf: "center", marginTop: "20px" }}>
                        <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <img className='mini' src={search} alt='tq-img' />
                            <h4 style={{color:"#5959C5",textAlign:"center"}} >DISCOVER</h4>
                            <p style={{color:"#000000",opacity:"0.6"}}>Discover insights from industry experts in various fields.</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} style={{ alignSelf: "center", marginTop: "20px" }}>
                    <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <img className='mini' src={increase} alt='tq-img' />
                            <h4 style={{color:"#5959C5",textAlign:"center"}} >ADVANCE</h4>
                            <p style={{color:"#000000",opacity:"0.6"}}>Advance your career and 
                            personal development.</p>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

export default Initiative;
