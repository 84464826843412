import React, { useRef, useEffect } from 'react';
import '../../Infrastructure/Infrastructure.css';
import '../../HighSchoolComponents/Gallery/Gallery.css';
import './EventsGallery.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import graduation1 from '../../Assets/events/graduation/graduation1.JPG';
import graduation2 from '../../Assets/events/graduation/graduation2.JPG';
import graduation3 from '../../Assets/events/graduation/graduation3.JPG';
import graduation4 from '../../Assets/events/graduation/graduation4.JPG';
import graduation5 from '../../Assets/events/graduation/graduation5.JPG';
import graduation6 from '../../Assets/events/graduation/graduation6.JPG';
import graduation7 from '../../Assets/events/graduation/graduation7.JPG';
import graduation8 from '../../Assets/events/graduation/graduation8.JPG';
import graduation9 from '../../Assets/events/graduation/graduation9.JPG';
import graduation10 from '../../Assets/events/graduation/graduation10.JPG';
import graduation11 from '../../Assets/events/graduation/graduation11.JPG';
import graduation12 from '../../Assets/events/graduation/graduation12.JPG';
import graduation13 from '../../Assets/events/graduation/graduation13.JPG';
import graduation14 from '../../Assets/events/graduation/graduation14.JPG';
import graduation15 from '../../Assets/events/graduation/graduation15.JPG';


import house1 from '../../Assets/events/InterHouse/house1.JPG';
import house2 from '../../Assets/events/InterHouse/house2.JPG';
import house3 from '../../Assets/events/InterHouse/house3.JPG';
import house4 from '../../Assets/events/InterHouse/house4.JPG';
import house6 from '../../Assets/events/InterHouse/house6.JPG';
import house7 from '../../Assets/events/InterHouse/house7.JPG';


import fest1 from '../../Assets/events/Nationalfestival/fest1.JPG';
import fest2 from '../../Assets/events/Nationalfestival/fest2.JPG';
import fest3 from '../../Assets/events/Nationalfestival/fest3.JPG';
import fest4 from '../../Assets/events/Nationalfestival/fest4.JPG';
import fest5 from '../../Assets/events/Nationalfestival/fest5.JPG';
import fest6 from '../../Assets/events/Nationalfestival/fest6.JPG';
import fest7 from '../../Assets/events/Nationalfestival/fest7.JPG';
import fest8 from '../../Assets/events/Nationalfestival/fest8.JPG';
import fest9 from '../../Assets/events/Nationalfestival/fest9.JPG';
import fest10 from '../../Assets/events/Nationalfestival/fest10.JPG';
import fest11 from '../../Assets/events/Nationalfestival/fest11.JPG';
import fest12 from '../../Assets/events/Nationalfestival/fest12.JPG';
import fest13 from '../../Assets/events/Nationalfestival/fest13.JPG';
import fest14 from '../../Assets/events/Nationalfestival/fest14.JPG';
import fest15 from '../../Assets/events/Nationalfestival/fest15.JPG';
import fest16 from '../../Assets/events/Nationalfestival/fest16.JPG';
import fest17 from '../../Assets/events/Nationalfestival/fest17.JPG';
import fest18 from '../../Assets/events/Nationalfestival/fest18.JPG';
import fest19 from '../../Assets/events/Nationalfestival/fest19.JPG';
import fest20 from '../../Assets/events/Nationalfestival/fest20.JPG';
import fest21 from '../../Assets/events/Nationalfestival/fest21.JPG';
import fest22 from '../../Assets/events/Nationalfestival/fest22.JPG';
import fest23 from '../../Assets/events/Nationalfestival/fest23.JPG';
import fest24 from '../../Assets/events/Nationalfestival/fest24.JPG';
import fest25 from '../../Assets/events/Nationalfestival/fest25.JPG';

import prize1 from '../../Assets/events/prizeday/prize1.JPG';
import prize2 from '../../Assets/events/prizeday/prize2.JPG';
import prize3 from '../../Assets/events/prizeday/prize3.JPG';
import prize4 from '../../Assets/events/prizeday/prize4.JPG';
import prize5 from '../../Assets/events/prizeday/prize5.JPG';
import prize6 from '../../Assets/events/prizeday/prize6.JPG';
import prize7 from '../../Assets/events/prizeday/prize7.JPG';
import prize8 from '../../Assets/events/prizeday/prize8.JPG';
import prize9 from '../../Assets/events/prizeday/prize9.JPG';
import prize10 from '../../Assets/events/prizeday/prize10.JPG';
import prize11 from '../../Assets/events/prizeday/prize11.JPG';
import prize12 from '../../Assets/events/prizeday/prize12.JPG';
import prize13 from '../../Assets/events/prizeday/prize13.JPG';
import prize14 from '../../Assets/events/prizeday/prize14.JPG';
import prize15 from '../../Assets/events/prizeday/prize15.JPG';
import prize16 from '../../Assets/events/prizeday/prize16.JPG';
import prize17 from '../../Assets/events/prizeday/prize17.JPG';
import prize18 from '../../Assets/events/prizeday/prize18.JPG';
import prize19 from '../../Assets/events/prizeday/prize19.JPG';
import prize20 from '../../Assets/events/prizeday/prize20.JPG';
import prize21 from '../../Assets/events/prizeday/prize21.JPG';
import prize22 from '../../Assets/events/prizeday/prize22.JPG';
import prize23 from '../../Assets/events/prizeday/prize23.JPG';
import prize24 from '../../Assets/events/prizeday/prize24.JPG';
import prize25 from '../../Assets/events/prizeday/prize25.JPG';
import prize26 from '../../Assets/events/prizeday/prize26.JPG';
import prize27 from '../../Assets/events/prizeday/prize27.JPG';
import prize28 from '../../Assets/events/prizeday/prize28.JPG';

import robo1 from '../../Assets/events/Roboticsevents/robo1.JPG';
import robo2 from '../../Assets/events/Roboticsevents/robo2.JPG';
import robo3 from '../../Assets/events/Roboticsevents/robo3.JPG';
import robo4 from '../../Assets/events/Roboticsevents/robo4.JPG';
import robo5 from '../../Assets/events/Roboticsevents/robo5.JPG';
import robo6 from '../../Assets/events/Roboticsevents/robo6.JPG';
import robo7 from '../../Assets/events/Roboticsevents/robo7.JPG';
import robo8 from '../../Assets/events/Roboticsevents/robo8.JPG';
import robo9 from '../../Assets/events/Roboticsevents/robo9.JPG';


import bee1 from '../../Assets/events/spellbee/bee1.JPG';
import bee2 from '../../Assets/events/spellbee/bee2.JPG';
import bee3 from '../../Assets/events/spellbee/bee3.JPG';
import bee4 from '../../Assets/events/spellbee/bee4.JPG';
import bee5 from '../../Assets/events/spellbee/bee5.JPG';
import bee6 from '../../Assets/events/spellbee/bee6.JPG';
import bee7 from '../../Assets/events/spellbee/bee7.JPG';
import bee8 from '../../Assets/events/spellbee/bee8.JPG';
import bee9 from '../../Assets/events/spellbee/bee9.JPG';


// ... import other images as needed

const EventsGallery = () => {
    const mainSlider = useRef(null);
    const navSlider = useRef(null);

    useEffect(() => {
        if (mainSlider.current && navSlider.current) {
            const mainSlick = mainSlider.current.innerSlider;
            const navSlick = navSlider.current.innerSlider;
            if (mainSlick && navSlick) {
                mainSlick.slickGoTo(0);
                navSlick.slickGoTo(0);
            }
        }
    }, []);

    const handleNavClick = (index) => {
        if (mainSlider.current) {
            mainSlider.current.slickGoTo(index);
        }
    };

    const mainSettings = {
        dots: false,
        infinite: true,
        // speed: 500, autoplay: true,
        // autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: navSlider.current,
        afterChange: (index) => {
            if (navSlider.current) {
                navSlider.current.slickGoTo(index);
            }
        }
    };
    const navSettings = {
        dots: false,
        infinite: true,
        // speed: 500, autoplay: true,
        // autoplaySpeed: 3000, 
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: mainSlider.current,
        responsive: [
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
        beforeChange: (current, next) => {
            if (mainSlider.current) {
                mainSlider.current.slickGoTo(next);
            }
        }
    };

    const images = {
        NationalFestivals: [fest1, fest2, fest3, fest4, fest5, fest6, fest7, fest8, fest9, fest10, fest11, fest12, fest13, fest14, fest15, fest16, fest17, fest18, fest19, fest20, fest21, fest22, fest23, fest24, fest25],
        Graduation: [graduation1, graduation2, graduation3, graduation4, graduation5, graduation6, graduation7, graduation8, graduation9, graduation10, graduation11, graduation12, graduation13, graduation14, graduation15],
        House:[
            house3 ,
            house1, 
house2, 
house4 ,
house6 ,
house7 


        ],

        NationalFestival: [fest1,
            fest2,
            fest3,
            fest4,
            fest5,
            fest6,
            fest7,
            fest8,
            fest9,
            fest10,
            fest11,
            fest12,
            fest13,
            fest14,
            fest15,
            fest16,
            fest17,
            fest18,
            fest19,
            fest20,
            fest21,
            fest22,
            fest23,
            fest24,
            fest25,],
        PrizeDay: [
            prize1,
            prize2,
            prize3,
            prize4,
            prize5,
            prize6,
            prize7,
            prize8,
            prize9,
            prize10,
            prize11,
            prize12,
            prize13,
            prize14,
            prize15,
            prize16,
            prize17,
            prize18,
            prize19,
            prize20,
            prize21,
            prize22,
            prize23,
            prize24,
            prize25,
            prize26,
            prize27,
            prize28,
        ],
        RoboticsEvents: [
            robo1,
            robo2,
            robo3,
            robo4,
            robo5,
            robo6,
            robo7,
            robo8,
            robo9,
        ],
        SpellBee: [
            bee1,
            bee2,
            bee3,
            bee4,
            bee5,
            bee6,
            bee7,
            bee8,
            bee9,
        ],

    };

    const renderSlider = (category) => (
        <div className='Gallery'>
            <div className='container'>
                <Slider className='mt-reverse2' {...mainSettings} ref={mainSlider}>
                    {images[category].map((image, idx) => (
                        <div key={idx}>
                            <div className="white-bg">
                                <img className='gallery' src={image} alt={`gallery ${idx}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {/* <Slider {...navSettings} >
                {images[category].map((image, idx) => (
                    <div key={idx} onClick={() => handleNavClick(idx)}>
                        <img className='gallery min-img' src={image} alt={`gallery ${idx}`} />
                    </div>
                ))}
            </Slider> */}
        </div>
    );

    return (
        <div className='position-relative aboutGallery'>
            <div className='blue-content blue-bg'>
                <div className='container'>
                    <h3>GALLERY</h3>
                </div>
            </div>
            <div className='container'>
                <Tabs>
                    <TabList className="tab-margin">
                        <Tab className='tab-label'>Inter House </Tab>
                        <Tab className='tab-label'>National Festivals</Tab>
                        <Tab className='tab-label'>Graduation </Tab>
                        <Tab className='tab-label'>Field Trip</Tab>
                        <Tab className='tab-label'>Prize Day</Tab>
                        <Tab className='tab-label'>Robotics Events</Tab>
                        <Tab className='tab-label'>Spell Bee</Tab>
                    </TabList>
                    <TabPanel>
                        {renderSlider('House')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('NationalFestivals')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('Graduation')}
                    </TabPanel>
                    
                    <TabPanel>
                        {renderSlider('NationalFestival')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('PrizeDay')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('RoboticsEvents')}
                    </TabPanel>
                    <TabPanel>
                        {renderSlider('SpellBee')}
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default EventsGallery;
