import React from 'react'
import pschool from '../../Assets/highschool.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const school = [
    {
        schooltitle:"High school",        
        schooldescription: "The high school consists of Standard IX and X. They are governed by the regulations and syllabus of ICSE (Indian Certificate of Secondary Education Examination).The students are enkindled with the thrill of discovery, informal curiosity, and creativity, with a deeper passion for learning, understanding, and negotiating with Various concepts. They are guided and motivated by their esteemed teachers to build their core strengths. In school, they get ample opportunities for broadening their vision and developing their various skills.",

        
        schooldescription2: "The curriculum prepares them for life. Once in school, the students are encouraged, educated, and prepared to face the challenges of the world as confident and competent young girls." ,        


        schooldescription3:"Daily Schedule",
        schooldescription4:"Standard IX and X timings are from 8:15 am to 3:00 pm",
        image: pschool,
       
    },
    
];
const School = () => {
  return (
    <div className="schedulecolor">
        {school.map((school, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={school.schooltitle}
                    schooldescription={school.schooldescription}
                    schooldescription2={school.schooldescription2}
                    schooldescription3={school.schooldescription3}
                    schooldescription4={school.schooldescription4}
                    image={school.image}
                    />
                ))}
    </div>
  )
}

export default School