import React, { useEffect }  from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import stump from '../../Assets/stump.svg'
import pop from '../../Assets/dot.svg'
import story1 from '../../Assets/Story1.png'
import story2 from '../../Assets/Story2.png'
import story3 from '../../Assets/Story3.png'

const SuccessStory = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,

                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative mbb-100' >
            <img className='stump' src={stump} alt='mesh' />
            <img className='pop' style={{ position: "absolute", right: "0", top: "25%", }} src={pop} alt='flask' />
            <div className='container SuccessStory'>
                <div className='position-relative mb-5'>
                    <h2   className='line'>success stories</h2>
                </div>
                <Slider {...settings}>
                    <div className='position-relative'>
                        <img className='board' src={story1} alt='board' />
                        {/* href='https://baldwingirls.edu.in/congratulations-to-rhea-bhansali-for-winning-a-silver-medal-in-gymnastics-under-17-category-at-the-65th-national-school-games-2019-20/' */}
                        <a  target='_blank' ><p data-aos="zoom-out-right" data-aos-duration="2000" className='stu-suc-stories' >CISCE National Level Swim Meet Results of all our swimmers from Baldwin Girls’High School
                            
                          
                        </p> </a>
                    </div>
                    <div className='position-relative'>
                        <img className='board' src={story2} alt='board' />
                        {/* href='https://baldwingirls.edu.in/zara-farin-an-ex-student-of-bghs-at-the-ground-breaking-ceremony-of-computer-design-research-and-learning-center/'  */}
                        <a target='_blank'><p data-aos="zoom-out-right" data-aos-duration="2000"  className='stu-suc-stories' >Zara Farin, an ex-student of BGHS at THE GROUND BREAKING CEREMONY of Computer Design Research and Learning Center</p></a>
                    </div>
                    <div className='position-relative'>
                        <img className='board' src={story3} alt='board' />
                        {/* href='https://baldwingirls.edu.in/cisce-national-level-swim-meet-2022-%ef%bf%bc/' */}
                        <a  target='_blank'><p data-aos="zoom-out-right" data-aos-duration="2000"  className='stu-suc-stories'  >   Congratulations to Rhea Bhansali for winning a Silver Medal in Gymnastics, under 17 category at the 65th National School Games 2019 -20 </p></a>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default SuccessStory