import React from 'react'
import pschool from '../../Assets/middleschool.png'
import SchoolCard from '../../PrePrimaryComponents/SchoolCard/SchoolCard';
const school = [
    {
        schooltitle:"Middle school",        
        schooldescription: "  The middle school consists of Standard VI, VII, and VIII and is considered to be the stepping stones for higher education. The curriculum is best suited to generate a healthy respect for hard work and to develop sound formal study skills. The students are motivated and guided by expert teachers to prepare them for the rigorous academic requirements and become independent research workers.",

        schooldescription2: "The school stresses punctuality, discipline, and leadership qualities. The students are trained to achieve greater heights.", 
        schooldescription3:"Daily Schedule",
        schooldescription4:"Standard VI, VII, VIII timings are from 8:15 am to 3:00 pm",
        image: pschool,  
        
      


    },
    
];
const School = () => {
  return (
    <div className="schedulecolor">
        {school.map((school, index) => (
                    <SchoolCard
                    key={index} 
                    schooltitle={school.schooltitle}
                    schooldescription={school.schooldescription}
                    schooldescription2={school.schooldescription2}
                    schooldescription3={school.schooldescription3}
                    schooldescription4={school.schooldescription4}
                    image={school.image}
                    />
                ))}
    </div>
  )
}

export default School