import React, { useRef, useEffect } from 'react'
import eventsimg from '../../Assets/sports.png'
import eventsimgmobile from '../../Assets/sportsmobile.png'
const Eventsbanner = () => {
 
  return (
    <div className='position-relative dark'>
    <img className='w-100 mob-banner-h desk-block' src={eventsimg} />
    <img className='w-100 mob-banner-h mob-block' src={eventsimgmobile} />
      <div className='event-banner-content'>
        <h2>SPORTS</h2>
        <div className='breadcrumbs'>
          <span className='border-left'></span>
          <span>Aquatic Sports</span>
          <span className='grey-round'></span>
          <span> Team Sports</span>
          <span className='grey-round'></span>
          <span> Individual Sports & Mind Games</span>
          <span className='border-right'></span>
        </div>
      </div>
    </div>
  )
}


export default Eventsbanner