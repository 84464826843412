import React from 'react';
import GalleryUpdate from '../../GalleryUpdate';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import galery112 from '../../Assets/gallerymiddle/gallery112.jpg';
import galery113 from '../../Assets/gallerymiddle/gallery113.jpg';
import galerym1 from '../../Assets/gallerymiddle/gallerymiddle1.JPG';
import galerym2 from '../../Assets/gallerymiddle/gallerymiddle2.JPG';
import galerym3 from '../../Assets/gallerymiddle/gallerymiddle3.JPG';
import galerym4 from '../../Assets/gallerymiddle/gallerymiddle4.JPG';
import galerym5 from '../../Assets/gallerymiddle/gallerymiddle5.JPG';
import galerym6 from '../../Assets/gallerymiddle/gallerymiddle6.JPG';


const images = [ galery113, galerym1, galerym2, galerym3, galerym4, galerym5, galerym6, ];
const Gallery = () => {
    return (
        <div>
            <GalleryUpdate images={images} />
        </div>
    );
};

export default Gallery;
