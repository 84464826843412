import React from 'react'
import eventsimg from '../../Assets/Co-curricular.png'
import eventsimgmobile from '../../Assets/Co-curricularmobile.png'
const Eventsbanner = () => {
  return (
    <div className='position-relative dark'>
      <img className='w-100 mob-banner-h desk-block' src={eventsimg} />
      <img className='w-100 mob-banner-h mob-block' src={eventsimgmobile} />
      <div className='event-banner-content'>
        <h2>Co-curricular</h2>
        <div className='breadcrumbs'>
          <span className='border-left'></span>
          <span>Performing Arts</span>
          <span className='grey-round'></span>
          <span>Visual Arts</span>
          <span className='grey-round'></span>
          <span>Multidisciplinary Activities</span>
          <span className='border-right'></span>
        </div>
      </div>
    </div>
  )
}

export default Eventsbanner