import React from 'react';
import GalleryUpdate from '../../GalleryUpdate';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import galery from '../../Assets/gallery/gallery1.JPG';
import galery2 from '../../Assets/gallery/gallery2.jpg';
import galery3 from '../../Assets/gallery/gallery3.JPG';
import galery4 from '../../Assets/gallery/gallery4.JPG';
import galery5 from '../../Assets/gallery/gallery5.JPG';
import galery6 from '../../Assets/gallery/gallery6.JPG';
import galery7 from '../../Assets/gallery/gallery7.jpg';
import galery8 from '../../Assets/gallery/gallery8.JPG';
import galery9 from '../../Assets/gallery/gallery9.JPG';

const images = [galery, galery2, galery3, galery4, galery5, galery6, galery7, galery8, galery9];
const Gallery = () => {
    return (
        <div>
            <GalleryUpdate images={images} />
        </div>
    );
};


export default Gallery;
