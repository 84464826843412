import React from 'react'
import Alumnibanner from '../Components/AlumniComponent/Alumnibanner/Alumnibanner'
import Initiative from '../Components/AlumniComponent/Initiative/Initiative'
import MeetAlumni from '../Components/AlumniComponent/MeetAlumni/MeetAlumni'


const Alumni = () => {
  return (
    <div>
       <Alumnibanner/>
       <Initiative/>
       <MeetAlumni/>
    </div>
  )
}

export default Alumni