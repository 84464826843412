import React, { useEffect }  from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Findus.css'
import findusfb from '../Assets/findus-fb.png';
import findusyoutube from '../Assets/findus-youtube.svg';
import finduslinkdin from '../Assets/findus-linkdin.svg';
import findusinsta from '../Assets/findus-insta.png';
import gmain from '../Assets/gmain.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import facebook from '../Assets/facebook.svg';
import instagram from '../Assets/insta.svg';
import linkedin from '../Assets/linkdin.svg';
import youtube from '../Assets/youtube.svg';
import camera from '../Assets/G.svg';
import kite from '../Assets/kite.svg';
const Findus = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='position-relative'>
            <img className='kite' src={kite} alt='kite' />
            <div className=' find-us'>
                <div className='container'>
                    <div className='position-relative mb-5'>
                        <h2   className='line'>FIND US ON </h2>
                    </div>
                <p  className='mb-4 fw-600' style={{color:"#2D306F"}}>Connect with us across platforms</p>
                </div>
                <Row className='findus-width'>
                    <Col xs={12} md={3} style={{alignSelf:"center"}}>
                        <div className='position-relative findimg1'>
                            <a href='https://www.facebook.com/Baldwingirls'><img className="w-100  img1" src={findusfb} alt='findus-img' />
                                <div className='social-pos'>
                                    <img src={facebook} alt='fb' />
                                    <p>Facebook</p>
                                </div></a>
                        </div>
                        <div className='position-relative'>
                            <a href='https://www.instagram.com/baldwingirlshighschool/'> <img className="w-100  img4" src={findusinsta} alt='findus-img' />
                                <div className='social-pos'>
                                    <img src={instagram} alt='fb' />
                                    <p>Instagram</p>
                                </div></a>
                        </div>
                    </Col>
                    <Col xs={12} md={6} style={{alignSelf:"center"}}>
                        <div className='position-relative'>
                            <a href='https://www.youtube.com/watch?v=PHOwQ-SHp9o'><img style={{height:"unset"}} className="w-100  img2" src={findusyoutube} alt='findus-img' />
                                <div className='social-pos'>
                                    <img src={youtube} alt='fb' />
                                    <p>Youtube</p>
                                </div></a>
                        </div>
                    </Col>
                    <Col xs={12} md={3} style={{alignSelf:"center"}}>

                        <div className='position-relative findimg2'>
                            <a href='https://g.page/r/CYwAFefcuDUMEAE'> <img className="w-100  img3" src={gmain} alt='findus-img' />
                                <div className='social-pos'>
                                    <img src={camera} alt='fb' />
                                    <p>Google</p>
                                </div></a>
                        </div>
                        <div className='position-relative'>
                            <a href='https://www.linkedin.com/company/baldwingirls/'>  <img className="w-100  img4" src={finduslinkdin} alt='findus-img' />
                                <div className='social-pos'>
                                    <img src={linkedin} alt='fb' />
                                    <p>Linkedin</p>
                                </div></a>
                        </div>
                    </Col>
                </Row>

            </div>
        </div >
    )
}

export default Findus