import React from 'react'
import '../../Admission/Admission.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emoji from '../../Assets/admission.svg';
import emoji2 from '../../Assets/admission2.svg';
import emoji3 from '../../Assets/admission3.svg';
import AdmissionCard from '../../AdmissionCard/AdmissionCard';
const EventsAdmission = () => {
    const heading=""
    const admission = [
        // {
        //     emoji: emoji,
        //     title: "Instantly Download Our School Brochure",
        //     description: "Get to know us better with quick download of our school brochure. ",
        //     rel_btn: "Download Brochure",
        //     rel_btn_href: "https://example.com/brochure",
        // },
        // {
        //     emoji: emoji2,
        //     title: "Schedule an On Campus Tour",
        //     description: "See our campus up close and personal by scheduling a tour.",
        //     rel_btn: "Schedule a Tour",
        //     rel_btn_href: "https://example.com/schedule-tour",
        // },
        {
            emoji: emoji3,
            title: "Talk to  Members of our Admission Team",
            description: "Get in touch with our team for personalized assistance and information.",
            rel_btn: "Request Information",
            rel_btn_href: "https://portal.baldwingirls.edu.in/",
        },
    ];
    return (
        <div  className='position-relative wave2'>
        <div className='container ammission-mt'>
            <div className='position-relative mb-5'>
                <h2 className='line'>ADMISSION</h2>
            </div>
            <p className='mb-4 fw-600' style={{color:"#2D306F"}}>We are happy to see your interest in our school</p>
                <Row className='mb-5 activity-img' style={{justifyContent:"center"}}>
                        {admission.map((admission, index) => (
                    <Col key={index} md={12} xs={12} className='text-center'>
                            <AdmissionCard
                                key={index}
                                emoji={admission.emoji}
                                title={admission.title}
                                description={admission.description}
                                rel_btn={admission.rel_btn}
                                rel_btn_href={admission.rel_btn_href}
                            />
                    </Col>
                        ))}
                </Row>

        </div>
        </div>
    )
}

export default EventsAdmission