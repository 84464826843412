import React from 'react'
import '../../PrePrimaryComponents/Choice/Choice.css'
import choice_img from '../../Assets/mid.png'
import ChoiceCard from '../../PrePrimaryComponents/ChoiceCard/ChoiceCard';

const choice = [
    {
        choicetitle:"Why are we the best choice for your child?",        
        choicedescription: "Empowering tomorrow's Leaders with an emphasis on critical thinking, problem-solving, and analytical skills, preparing students in classes 9 and 10.",
        number: "01" , 
        content: "Comprehensive Curriculum",
        sub_content:"Our carefully crafted curriculum prioritizes academic rigor, social-emotional well-being, and the development of core values, ensuring a well-rounded education for every student.",

        number2: "02" , 
        content2: "Imparting Quality Education",
        sub_content2:"Prepares for success in the future.",


        number3: "03" , 
        content3: "Collaborative Learning Community",
        sub_content3:"We foster a collaborative learning environment where students work together, share ideas, and collaborate on projects, developing essential teamwork and communication skills.",

        choiceimage:choice_img,    
    },    
];
const Choice = () => {
  return (
    <div>
        {choice.map((choice, index) => (
                    <ChoiceCard
                    key={index} 
                    choicetitle={choice.choicetitle}
                    choicedescription={choice.choicedescription}
                    number={choice.number}
                    content={choice.content}
                    sub_content={choice.sub_content}
                    number2={choice.number2}
                    content2={choice.content2}
                    sub_content2={choice.sub_content2}
                    number3={choice.number3}
                    content3={choice.content3}
                    sub_content3={choice.sub_content3}
                    choiceimage={choice.choiceimage}
                    />
                ))}
    </div>
  )
}
export default Choice