import React from 'react'
import '../../MiddleSchoolComponents/AccordionInfo/Accordion.css'
import Accordion from 'react-bootstrap/Accordion';
const Accordion_ACRA = ({sections}) => {
    return (
        <div>
            <Accordion >
                {sections.map((section, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{section.label}</Accordion.Header>
                        <Accordion.Body>
                            <p className='content'>{section.content}</p>
                            <p className='content'>{section.subcontent}</p>
                            <p className='content'>{section.content2}</p>
                            <p className='content'>{section.subcontent2}</p>
                            <p className='content'>{section.content3}</p>
                            <p className='content'>{section.content4}</p>
                            <p className='content'>{section.content5}</p>
                            <p className='content'>{section.content6}</p>
                            <p className='content'>{section.content7}</p>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>      
        </div>
    )
}

export default Accordion_ACRA