import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Calendar.css';
import cal1 from '../../Assets/cal1.png';
import cal2 from '../../Assets/cal2.png';
import cal3 from '../../Assets/cal3.png';
import cal4 from '../../Assets/cal4.png';
import cal5 from '../../Assets/cal5.png';
import cal6 from '../../Assets/cal6.png';
import cal7 from '../../Assets/cal7.png';
import cal8 from '../../Assets/cal8.png';
import cal9 from '../../Assets/cal9.png';
import cal10 from '../../Assets/cal10.png';
import cal11 from '../../Assets/cal11.png';
import cal12 from '../../Assets/cal12.png';



const Calendar = () => {
  const calendarRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#Calander' && calendarRef.current) {
      calendarRef.current.scrollIntoView({ behavior: 'smooth',top:0,  });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500, autoplay: true,
        autoplaySpeed: 3000, 
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='position-relative mt-margin Caldendar' ref={calendarRef} id="Calander">
      <div className='container SuccessStory'>
        <div className='position-relative mb-5'>
          <h2 className='line'>Calendar</h2>
        </div>
        <Slider {...settings}>
          <div className='position-relative'>
            <img className='board' src={cal1} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal2} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal3} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal4} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal5} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal6} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal7} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal8} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal9} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal10} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal11} alt='board' />
          </div>
          <div className='position-relative'>
            <img className='board' src={cal12} alt='board' />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Calendar;
