import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick';
import './Announcement.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import noticeboard from '../Assets/noticeboard.png'
import noticeboard2 from '../Assets/noticeboard2.png'
import noticeboard3 from '../Assets/noticeboard3.png'
import noticeboard4 from '../Assets/noticeboard4.png'
import flask from '../Assets/flask.svg'

const Announcement = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow:1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative announcement'>
            {/* <img className='mesh' src={mesh} alt='mesh' /> */}
            <img className='flask' src={flask} alt='flask' />
            <div className='container Announcement'>
                <div className='position-relative mb-5'>
                    <h2   className='line'>ANNOUNCEMENTS</h2>
                </div>
                <h4   className='mb-4 fw-600' style={{color:"#2D306F"}}>Explore here for Updates</h4>
                <Slider {...settings}>
                    <div className='position-relative newsimg'>
                        <img className='w-100' style={{padding:"10px"}} src={noticeboard} alt='board' />
                    </div>
                    <div className='position-relative newsimg'>
                        <img className='w-100' style={{padding:"10px"}} src={noticeboard2} alt='board' />
                    </div>
                    <div className='position-relative newsimg'>
                        <img className='w-100' style={{padding:"10px"}} src={noticeboard3} alt='board' />
                    </div>
                    <div className='position-relative newsimg'>
                        <img className='w-100' style={{padding:"10px"}} src={noticeboard4} alt='board' />
                    </div>
                    
                </Slider>
            </div>
        </div>
    )
}

export default Announcement
