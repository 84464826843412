import React from 'react'
import './Programme.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Programme = () => {
    return (
        <div className='container mt-margin' >
            <div className='position-relative mb-5'>
                <h2 className='line'>The PRE -PRIMARY PROGRAMME</h2>
            </div>
                <Row className='mb-5 flex-direction programme '>
                    <Col md xs={6}>
                        <div className="setscard text-center">
                          <h3>Pre-KG</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity programm'>Apply now</a>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard text-center">
                          <h3>LKG</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity programm'>Apply now</a>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard text-center">
                          <h3>UKG</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity programm'>Apply now</a>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard text-center">
                          <h3>STANDARD 1</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity programm'>Apply now</a>
                        </div>
                    </Col>
                    <Col md xs={6}>
                        <div className="setscard text-center">
                          <h3>STANDARD 2</h3>
                        </div>
                        <div className='text-center'>
                            <a href='https://portal.baldwingirls.edu.in/'  target="_blank" className='activity programm'>Apply now</a>
                        </div>
                    </Col>
                </Row>
        </div>
    )
}

export default Programme