import React, { useEffect }  from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Featured.css'
import Marquee from "react-fast-marquee";
import marquee1 from '../Assets/marque1.svg';
import marquee2 from '../Assets/marque2.svg';
import marquee3 from '../Assets/marque3.svg';
import marquee4 from '../Assets/marque4.svg';
import mesh from '../Assets/mesh.svg'

const Featured = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='featured-mt position-relative'>
            <img className='mesh' src={mesh} alt='mesh' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2  className='line'>FEATURED </h2>
                </div>
                <p  className='mb-4 fw-600' style={{color:"#2D306F"}}>Explore our mentions</p>

           
                <div className="custom-dflex">
                    <img className=" marquee-img" src={marquee1} />
                    <img className=" marquee-img" src={marquee2} />
                    <img className=" marquee-img" src={marquee3} />
                    <img className=" marquee-img" src={marquee4} />
                </div>
                </div>
         
        </div>
    )
}

export default Featured