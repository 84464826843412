import React from 'react';
import FacultiesCard from '../FacultiesCard/FacultiesCard';
import facultiesp from '../../Assets/faculties.png';
import './Faculties.css'
const Faculties = () => {
    const facultiesData = {
        facultiestitle: "MEET OUR Enthusiastic FACULTIES",
        facultiesSubtitle: "The school boast of a diverse faculty, blending youth and experience, all  professionally dedicated."
    }

    return (
        <div>
            <FacultiesCard 
                facultiestitle={facultiesData.facultiestitle}
                facultiesSubtitle={facultiesData.facultiesSubtitle}
                facultiesp={facultiesp}
            />
        </div>
    );
}

export default Faculties;
