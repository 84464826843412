import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import rectangle from '../../Assets/Rectangle.svg';
import foterlogo from '../../Assets/footer-blue.png';
import './Crest.css';
const Crest = () => {
    return (
        <div className='position-relative crest'>
            <img className='rect2' src={rectangle} alt='' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>SCHOOL CREST</h2>
                </div>
                <Row >
                    
                    <Col md={4} className='text-center' style={{alignSelf:"center"}}>
                        <img className='small-logo' src={foterlogo} alt='footer-logo' />
                    </Col>
                    <Col md={8}>
                        <div className='custom-card' style={{padding:"30px 20px"}}>
                            <p>A white shield on a field of blue.</p>

                            <p>The torch denotes truth and light;</p>

                            <p>The open book, God's Word and Knowledge;</p>

                            <p>The Laurel depicts honour, and the Harp, Cultural development.</p>

                            <p>'Loyalty and Service' the motto demands faithfulness to God, to oneself, to the school and the nation together with concern and care for all life. </p>

                           
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Crest