
import React from 'react';
import Slider from 'react-slick';
import '../../Testimonials/Testimonials.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import emoji7 from '../../Assets/emoji7.png';
import emoji2 from '../../Assets/emoji2.svg';
import emoji3 from '../../Assets/emoji3.svg';
import wave from '../../Assets/wave.svg';
import kite from '../../Assets/kite.svg';

const Testimonials = () => {
    const testimonials = [
        {
            name: "Ada A Ballal",
            role: "Student  - President",
            emoji: emoji7,
            description1: "“I attended Baldwin Girls' High School for 13 formative years, shaping my growth. The passionate teachers foster leadership and lifelong learning. Serving as School President was transformative, with exceptional support.", 
 

            description2:"The school encourages exploring passions in academics, sports, and arts, ensuring a well-rounded education. Grateful for the memories at Baldwin Girls’ High School.”",
        },
        {
            name: "Niharika Ramesh",
            role: "Student",
            emoji: emoji2,
            description1: "“Attending Baldwin Girls' High School has been enriching. The expert teachers genuinely care and always offer support.",
            description2:"The school’s extracurricular activities and leadership opportunities helped me develop teamwork and communication skills. Baldwin's nurturing environment has made me excel. Thank you, Baldwin’s for shaping me.”"
        },

        

        {
            name: "Siya Manmohan",
            role: "Student",
            emoji: emoji3,
            description1: " “My experience at BGHS was transformative. Engaging in a diverse array of activities, from choir to sports and inter-school events, profoundly shaped my academic and extracurricular journey. ", 

           
            description2:"I'm deeply appreciative of the mentors and friendships that l've gained here, they continue to impact my life positively.”",
        },


       


        
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative mt-margin parentallign'>
            <img className='kite' src={kite} alt='kite' />
            <img className='wave' src={wave} alt='wave' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>Testimonials</h2>
                </div>
                <p className='mb-4 fw-600' style={{color:"#2D306F"}}>What Parents have to say about BGHS</p>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            name={testimonial.name}
                            role={testimonial.role}
                            emoji={testimonial.emoji}
                            description1={testimonial.description1}
                            description2={testimonial.description2}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;