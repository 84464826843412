import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick';
import './Testimonials.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import emoji from '../Assets/emoji.svg';
import emoji2 from '../Assets/emoji2.svg';
import emoji3 from '../Assets/emoji3.svg';
import wave from '../Assets/wave.svg';
import kite from '../Assets/kite.svg';

const Testimonials = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const testimonials = [
        {
            name: "Sahid Nizam & Farrah Anjum",
            role: "Parents",
            emoji: emoji,
            description1: "“Zara a quintessential Baldwin girl, epitomises the core of Baldwin Culture of perseverance, sagacity and independent thinking which enables her to scale new heights.",
            description2:"We, her parents are grateful to this institution for having played an important role in shaping her future and being a stepping stone for her success.”"
        },
        {
            name: "Vedha Laxminarayan",
            role: "Parent",
            emoji: emoji2,
            description1: "“To all the teachers and especially those that had to unlearn some traditional techniques of teaching and relearn digital teaching techniques.",
            description3:"A big thank you!",
            description2:" Even when the world came to a standstill, you made it move for your students by not letting their learning suffer.”"
        },

        {
            name: "Jasmiya Sheikh",
            role: "Parent",
            emoji: emoji3,
            description1: "“I am writing to let you know how pleased I am with the online classes, Where teachers are so hardworking creative and innovative. ",
            description2:"All the PPT’s , Videos and worksheets sent by the teachers are so good and informative. It totally reflects the kind of hard work, time and energy that the teachers are putting in to prepare such informative and interactive lessons.”"
        },
        
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative mtmargin parentallign'>
            <img className='kite' src={kite} alt='kite' />
            <img className='wave' src={wave} alt='wave' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2   className='line'>Testimonials</h2>
                </div>
                <p   className='mb-4 fw-600' style={{color:"#2D306F"}}>What Parents have to say about BGHS</p>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            name={testimonial.name}
                            role={testimonial.role}
                            emoji={testimonial.emoji}
                            description1={testimonial.description1}
                            description2={testimonial.description2}
                            description3={testimonial.description3}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;






