import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Ceremony.css'
import ceremony from '../../Assets/ceremony.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const Ceremony = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div>
            <div className='container Ceremony'>
                <Row>
                    <Col md={6} style={{alignSelf:"center"}}>
                        <div className="topper">
                            <h3  data-aos="fade-right"   data-aos-duration="2000">INVESTITURE CEREMONY</h3>
                            <p data-aos="fade-right" className='fw-600'  style={{marginBottom:"50px"}} data-aos-duration="2000">2024-2025</p>
                            <h2 data-aos="fade-right" style={{fontSize:"22px",textTransform:"uppercase",marginBottom:"20px"}}  data-aos-duration="2000">List of Student council 2024-25</h2>
                            <p>School President – Aaheli Dutta</p>
                            <p>School Vice-President – Aandrita G</p>
                            <p>School Secretary – Swara S</p>
                            <p>School Games Captain – Harshini P</p>
                            <p>School Games Vice-Captain – Priyadarshini Mansingh</p>
                        </div>

                    </Col>
                    <Col md={6}>
                    <div className='rectangle position-relative'>
                                <img className='rectangle' src={ceremony} alt='ceremony' />
                            </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Ceremony
