import React, { useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AOS from "aos";
import "aos/dist/aos.css";
import './Accordion.css'
import Accordion from 'react-bootstrap/Accordion';
const Accordion_ACR = ({ sections }) => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
    }, []);
    return (
        <div>
            <Accordion>
                {sections.map((section, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{section.label}</Accordion.Header>
                        <Accordion.Body>
                            <Row >
                                <Col md={6} className='infowidth'>
                            <p className='subcontent fw-600'>{section.subcontent}</p>
                            <p className='content'>{section.content}</p>
                            <p className='content'>{section.content2}</p>
                            <p className='content'>{section.content3}</p>
                            <p className='content'>{section.content4}</p>
                            <p className='content'>{section.content5}</p>
                            <p className='content'>{section.content6}</p>
                            <p className='content'>{section.content7}</p>

                                </Col>
                                <Col md={6} className='infowidth'>
                                <p className='subcontent fw-600' style={{height:"25px"}}>{section.subcontent2}</p>
                                <p className='content'>{section.contentt}</p>
                                <p className='content'>{section.content22}</p>
                                <p className='content'>{section.content23}</p>
                                <p className='content'>{section.content24}</p>
                                <p className='content'>{section.content25}</p>
                                <p className='content'>{section.content26}</p>
                                <p className='content'>{section.content27}</p>

                                </Col>
                            </Row>
                            

                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    )
}

export default Accordion_ACR