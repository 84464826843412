import React from 'react';
import './MeetAlumni.css'
import emoji7 from '../../Assets/emoji7.png';
import caseimg from '../../Assets/caseimg.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const MeetAlumni = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
    };
    return (
        <div className='mt-margin fontmeet'>
            <div className='container alumnimeet'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>MEET OUR ALUMNI</h2>
                </div>
                <Slider {...settings}>
                    <div style={{display:"flex",justifyContent:"center"}} >
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px",justifyContent:"center" }}>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Akshatha Murthy</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Businesswoman, fashion designer, venture capitalist and wife of UK's prime minister,
                                    Rishi Sunak</p>
                                </div>
                            </div>

                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Kriti Kharbanda</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian Actresses</p>
                                </div>
                            </div>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Chaitra Vasudevan</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian Actresses, Anchor, Producer & Entreprenuer</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px" }}>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Aindrita Ray</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian Actresses</p>
                                </div>
                            </div>

                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Shubra Aiyappa</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian Actresses</p>
                                </div>
                            </div>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Trishikha Wodeyar</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }} >Wife of Wadiyar of Mysore</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px" }}>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Shobhna Samarth</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian director and actress</p>
                                </div>
                            </div>

                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Sapthami Gowda</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Indian Actresses</p>
                                </div>
                            </div>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Audrey Maben</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>India's first woman microlight flight instructor</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px" }}>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Christine Brown</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Member of the relay team that won India's first gold at the Asian Games in 1984</p>
                                </div>
                            </div>

                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Loraine Verghese</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>English Channel Swimmer</p>
                                </div>
                            </div>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Natasha Sagar</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>CEO of THAP, Winner of 12 national medals & holder 
                                    of 2 national records in the Asian Grand Prix</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px" }}>
                            <div className='custom-card' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={emoji7} alt='emoji' />
                                    <h4 style={{ color: "#5959C5", marginLeft: "20px", fontWeight: "600" }}>Nirupama Sunderraj</h4>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <img style={{ height: "45px" }} src={caseimg} alt='emoji' />
                                    <p style={{ color: "#000000", opacity: "0.8", marginLeft: "20px", maxWidth: "270px" }}>Co-founder of ALTFIT, Athlete, Commonwealth Games 2010, 16x National Gold Medalist</p>
                                </div>
                            </div>

                            
                           
                        </div>
                    </div>

                </Slider>
            </div>

        </div>
    );
}

export default MeetAlumni;
