import React from 'react';
import GalleryUpdate from '../../GalleryUpdate';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import galeryp1 from '../../Assets/galleryhighschool/galleryhighschool1.JPG';
import galeryp2 from '../../Assets/galleryhighschool/galleryhighschool2.JPG';
import galeryp3 from '../../Assets/galleryhighschool/galleryhighschool3.JPG';
import galeryp4 from '../../Assets/galleryhighschool/galleryhighschool4.JPG';
import galeryp5 from '../../Assets/galleryhighschool/galleryhighschool5.JPG';
import galeryp6 from '../../Assets/galleryhighschool/galleryhighschool6.JPG';


const images = [ galeryp1, galeryp2, galeryp3, galeryp4, galeryp5,galeryp6,];
const Gallery = () => {
    return (
        <div>
            <GalleryUpdate images={images} />
        </div>
    );
};

export default Gallery;
