import React from 'react';
import faculties_img1 from '../../Assets/faculties-img1.svg';
import faculties_img2 from '../../Assets/faculties-img2.svg';
import faculties_img3 from '../../Assets/faculties-img3.svg';

const FacultiesCard = ({ facultiestitle, facultiesSubtitle, facultiesp }) => {
    return (
        <div>

            <div className='blue-bg position-relative'>
                <img className='faculties1' src={faculties_img1} alt='design' />
                <img className='faculties2' src={faculties_img2} alt='design' />
                <img className='faculties3' src={faculties_img3} alt='design' />
                <div className='container'>
                    <div className='blue-content'>
                        <h3>{facultiestitle}</h3>
                        <p>{facultiesSubtitle}</p>
                    </div>
                </div>
            </div>
            <div className='white-bg text-center mt-margine-reverse'>
                <img className="w-100" src={facultiesp} alt='faculties' />
            </div>

        </div>

    );
}

export default FacultiesCard;
