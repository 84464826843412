import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Aboutschool.css';
import Container from 'react-bootstrap/Container';

const Aboutschool = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='position-relative desk-padding'>
                <div className='blue-bg ourblue' style={{maxWidth:"100% !important;"}}>
            <Container>
                <div className='ourschool'>

                <h4>About Our school</h4>
                <p>Baldwin Girls' High School, #90 Richmond Road, Bengaluru, was established in 1880. It is “A unit 
                of Baldwin Methodist Educational Society” and is affiliated to the Council for the ICSE, New Delhi. The school provides an ideal atmosphere for learners to acquire and imbibe skills necessary for the physical, mental, social, and intellectual development of the students.</p>
                </div>

               
            </Container>
                </div>
        </div>
    )
}

export default Aboutschool
