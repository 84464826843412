import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Eventsbanner from '../Components/SportsComponents/Eventsbanner/Eventsbanner'
import Ceremony from '../Components/SportsComponents/Ceremony/Ceremony'
import EventsAdmission from '../Components/SportsComponents/EventsAdmission/EventsAdmission'
import EventsInformation from '../Components/SportsComponents/EventsInformation/EventsInformation'
// import EventsGallery from '../Components/SportsComponents/EventsGallery/EventsGallery'
import EventsOtherSection from '../Components/SportsComponents/EventsOtherSection/EventsOtherSection'
import Games from '../Components/SportsComponents/Games/Games';

const Sports = () => {
  const ScrollToTop = () => {
    const { pathname, state } = useLocation();
  
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (state && state.targetSection) {
          const targetSection = document.querySelector(state.targetSection);
          if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100); // Adding a slight delay to ensure scroll happens after the render
    }, [pathname, state]);
  
    return null;
  };
  return (
    <div style={{background:"#FFF"}}>
      <ScrollToTop/>
        <Eventsbanner/> 
        <Ceremony/>  
        <Games/>
        {/* <EventsGallery/> */}
        <EventsAdmission/> 
        <EventsInformation/> 
        <EventsOtherSection/>        
    </div>
  )
}

export default Sports