import React from 'react'
import './Banner.css'
import BannerCard from '../BannerCard/BannerCard'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bannerImage from '../../Assets/preprimary.png';
import bannerImage2 from '../../Assets/preprimary.png';
import bannerImagemob from '../../Assets/pre-mob.png';
import bannerImagemob2 from '../../Assets/pre-mob.png';

const banner = [
    {
        bannerImage:bannerImage,
        bannerImagemob:bannerImagemob,
        bannerHeading: "The Pre-Primary programme",
        bannerDescription: "Offers a holistic, inquiry-driven educational model for children",
    },
    {
        bannerImage:bannerImage2,
        bannerImagemob:bannerImagemob2,
        bannerHeading: "Parents2",
        bannerDescription: "2222222222Zara a quintessential Balwanian girl, epitomises the cores of Balwanian Culture of perseverance, sagacity and independent thinking which enables her to scale new heights. We, her parents are grateful to this institution for having played an important role in shaping her future and being a stepping stone for her success.",
    },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500, autoplay: true,
        autoplaySpeed: 3000, 
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};
const Banner = () => {
    return (
        <div className='Banner'>

            <Slider {...settings}>
                {banner.map((banner, index) => (
                    <BannerCard
                    key={index} 
                    bannerImage={banner.bannerImage}
                    bannerImagemob={banner.bannerImagemob}
                    bannerHeading={banner.bannerHeading}
                    bannerDescription={banner.bannerDescription}
                    />
                ))}
            </Slider>

        </div>
    )
}

export default Banner