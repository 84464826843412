import React from 'react'
import Accordion_ACR from '../Accordion/Accordion'

const Information = () => {
  const sectionsinfo = [
    {
        label: 'Admission Procedure',
        content: '1. Fill the Registration cum admission form (Forms are available both online as well as offline).',
        content2: '2. Submit the duly filled form along with required documents (Documents can be submitted later in case of any difficulty).',
        content3: '3. Admission desk will verify the form documents and revert.',
        content4: '4. An interactive session with a simple test will be conducted.',
        content5: '5. Following the test, if selected, admission will be granted.',
        content6: '6. Once admission is granted, parents are required to complete all the admission formalities within 10 working days to block the seat.',
    },
    {
      label: 'Age Criteria',
      content: ' • 3 years for Pre-KG as on 1st June of the year of admission.',
      content3: '• 4 years for LKG as on 1st June of the year of admission.',
      content4: '• 5 years for UKG as on 1st June of the year of admission.',
      content5: "• 6 years for Standard 1 as on 1st June of the year of admission.",
     

  },
    {
        label: 'Documents Required',
        content: ' • Parents declaration form. ',
        content2: '• Original Birth Certificate (for reference). ',
        content3:"• A Xerox copy of the birth certificate attested by a Gazetted officer / Notary seal and signature.",
        content4: '• Submit a medical fitness certificate from CDMO/ registered medical practitioner.',
        content5: "• A copy of the Caste, Sub-Caste / Tribe Certificate in case of (SC/ST/OBC) only. ",
        content6: '• In case of NRI students, a copy of the passport has to be attached.',
        content7: '• Photographs of child and parents.',
    },
];
  return (
    <div className='container Admissionn accrwidth'>
        <div className='position-relative mb-5'>
                <h2 className='line'>ADMISSION INFORMATION</h2>
            </div>
            <Accordion_ACR  style={{width:"100%"}} sections={sectionsinfo} />
            <div className="noww">
              <a href="https://portal.baldwingirls.edu.in/" target="_blank" class="activity programm">Apply now</a>
            </div> 
    </div>
  )
}

export default Information