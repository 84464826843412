import React from 'react'
import "aos/dist/aos.css";
import './School.css'
import pschool from '../../Assets/pschool.png'
import SchoolCard from '../SchoolCard/SchoolCard';
const school = [
    {
        schooltitle:"Pre Primary School",        
        schooldescription: "The pre-primary wing consists of Pre-KG, LKG, UKG, Standard I and Standard II. Learning is thematic and activity-based. The main focus is on building language skills (listening, speaking, reading, writing), social, motor, and kinesthetic skills.",
        schooldescription2: "Teaching is aimed at making learning fun. In a free and stimulating environment, children’s intellectual, physical, cultural, artistic, and communication skills develop and flourish." ,  
        schooldescription3:"Daily Schedule",
        schooldescription4:"Pre-KG, LKG, UKG timings are from 8:15 am to 12:05 pm" ,      
        schooldescription5:"Standard I and II timings are from 8:15 am to 2:30 pm"  ,
        image: pschool,
       
    },
    
];
const School = () => {

  return (
    <div className="schedulecolor">
        {school.map((school, index) => (
                    <SchoolCard  
                    key={index} 
                    schooltitle={school.schooltitle}
                    schooldescription={school.schooldescription}
                    schooldescription2={school.schooldescription2}
                    schooldescription3={school.schooldescription3}
                    schooldescription4={school.schooldescription4}
                    schooldescription5={school.schooldescription5}
                    image={school.image}
                    />
                ))}
    </div>
  )
}

export default School