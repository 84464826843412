import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Alumnibanner.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import alumni from '../../Assets/alumni.png'
const Alumnibanner = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    return (
        <div className='p-70 school-w'>
            <Row>
                <Col xs={12} lg={8} style={{alignSelf:"center"}}>
                    <div className='pl-120' style={{maxWidth:"750px"}}>
                        <h2 data-aos="fade-right"   data-aos-duration="1500" style={{ color: "#353597", fontWeight: "700", fontSize: '40px' }}>Baldwin Girls' High School
                            Alumni Portal</h2>
                        <div style={{ margin: "20px 0" }}>
                            <p data-aos="fade-right"   data-aos-duration="1500" style={{ color: "#2D306F", fontWeight: "500", textAlign: "justify",paddingBottom:"12px" }}>Dear Alumni of Baldwin Girls' High School, Bengaluru,</p>
                            <p data-aos="fade-right"   data-aos-duration="1500" style={{ color: "#2D306F", fontWeight: "700", textAlign: "justify",paddingBottom:"12px" }}> ------ Be a Part of the Nest ------</p>
                            <p data-aos="fade-right"   data-aos-duration="1500" style={{ color: "#2D306F", fontWeight: "500", textAlign: "justify",paddingBottom:"12px" }}>Just because you've flown from the coop doesn’t mean you can’t stay connected with us and your worldwide friends. As a Baldwin Girls’ High School graduate, you’re part of a community that spreads across the globe and is very active and strong! That means more connections, more networking, and more opportunities when you stay with us.</p>
                        </div>
                    
                    </div>
                </Col>
                <Col xs={12} lg={4} style={{ alignSelf: "center", marginTop: "20px" }}>
                    <div style={{marginBottom:"20px"}}>
                        <img className='w-100' src={alumni} alt='tq-img' />
                        <p data-aos="fade-right"   data-aos-duration="1500"  style={{color:"#2D306F",opacity:"0.6",fontSize:"12px",textAlign:"center",paddingTop:"15px"}}>(Verification by Academic Institution would be required)</p>
                    </div>
                </Col>
            </Row>
            <div className='pl-120'>

                <h4 data-aos="fade-right"   data-aos-duration="1500"  style={{ color: "#2D306F", padding: "20px 0", fontWeight: "600" }}>Baldwinians, all who graduated before 2016:</h4>

                <p data-aos="fade-right"   data-aos-duration="1500"  style={{ color: "#2D306F", fontWeight: "600" }}>Kindly update your info—we are eager to know your name, where you are placed, what you are doing, when you graduated from the school... everything. Please let us know your details to keep you updated about your batch mates and also about the school and its achievements.</p>
                <div style={{marginTop:"60px"}}>
                    <a className='custom-btn' href='https://alumni.baldwingirls.edu.in/wp-login.php' target='_blank'>Login/Register</a>
                </div>
            </div>
        </div>
                
    );
}

export default Alumnibanner;
