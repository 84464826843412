import React from 'react'
import './Choice.css'
import choice_img from '../../Assets/choice.png';
import ChoiceCard from '../../PrePrimaryComponents/ChoiceCard/ChoiceCard';

const choice = [
    {
        choicetitle:"Why are we the best choice for your child?",        
        choicedescription: "Our Pre-KG to Standard 2 Early Years Programme promotes a culture of inquiry and exploration in a secure and enjoyable learning atmosphere.",
        number: "01" , 
        content: "Inquiry-Based Learning",
        sub_content:"Our Pre-Primary program fosters a culture of inquiry and exploration, igniting curiosity and a love for learning from an early age.",

        number2: "02" , 
        content2: "Holistic Development",
        sub_content2:"We prioritize the holistic development of young learners, focusing on their cognitive, social, emotional, and physical growth in a nurturing environment.",


        number3: "03" , 
        content3: "Play-Based Curriculum",
        sub_content3:"Our curriculum integrates play-based learning experiences, allowing children to develop critical thinking, problem-solving, and creativity through hands-on exploration.",

        choiceimage:choice_img,    
    },    
];
const Choice = () => {
  return (
    <div>
        {choice.map((choice, index) => (
                    <ChoiceCard
                    key={index} 
                    choicetitle={choice.choicetitle}
                    choicedescription={choice.choicedescription}
                    number={choice.number}
                    content={choice.content}
                    sub_content={choice.sub_content}
                    number2={choice.number2}
                    content2={choice.content2}
                    sub_content2={choice.sub_content2}
                    number3={choice.number3}
                    content3={choice.content3}
                    sub_content3={choice.sub_content3}
                    choiceimage={choice.choiceimage}
                    />
                ))}
    </div>
  )
}
export default Choice