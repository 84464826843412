import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';

const GalleryUpdate = ({ images }) => {
    const mainSliderG = useRef(null);
    const navSliderG = useRef(null);

    useEffect(() => {
        // Prevent the page from scrolling to the component on load
        const preventScroll = () => {
            window.scrollTo(0, 0);
        };

        // Listen for scroll events
        window.addEventListener('scroll', preventScroll);

        // Remove the scroll listener after a delay
        setTimeout(() => {
            window.removeEventListener('scroll', preventScroll);
        }, 1000);

        // Initialize slick sliders
        if (mainSliderG.current && navSliderG.current) {
            const mainSlick = mainSliderG.current.slick;
            const navSlick = navSliderG.current.slick;
            if (mainSlick && navSlick) {
                mainSlick.slickGoTo(0);
                navSlick.slickGoTo(0);
            }
        }

        return () => {
            window.removeEventListener('scroll', preventScroll);
        };
    }, []);

    const handleNavClick = (index) => {
        if (mainSliderG.current) {
            mainSliderG.current.slickGoTo(index);
        }
    };

    const mainSettings = {
        dots: false,
        infinite: true,
        // speed: 500,
        // autoplay: true,
        // autoplaySpeed: 3000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: navSliderG.current,
        afterChange: (index) => {
            if (navSliderG.current) {
                navSliderG.current.slickGoTo(index);
            }
        }
    };

    const navSettings = {
        dots: false,
        infinite: true,
        // speed: 500,
        // autoplay: true,
        // autoplaySpeed: 3000,
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: mainSliderG.current,
        responsive: [
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
        beforeChange: (current, next) => {
            if (mainSliderG.current) {
                mainSliderG.current.slickGoTo(next);
            }
        }
    };

    return (
        <div className='Gallery mt-margin'>
            <div className='blue-content blue-bg'>
                <div className='container'>
                    <h3>GALLERY</h3>
                </div>
            </div>
            <div className='container'>
                <Slider className='mt-reverse'
                    {...mainSettings}
                    ref={mainSliderG}
                >
                    {images.map((img, index) => (
                        <div key={index}>
                            <div className="white-bg">
                                <img className='gallery' src={img} alt={`gallery-${index}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {/* <Slider
                {...navSettings}
                ref={navSliderG}
            >
                {images.map((img, index) => (
                    <div key={index} onClick={() => handleNavClick(index)}>
                        <img className='gallery' src={img} alt={`gallery-thumbnail-${index}`} />
                    </div>
                ))}
            </Slider> */}
        </div>
    );
};

export default GalleryUpdate;
