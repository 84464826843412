import React from 'react';
import Slider from 'react-slick';
import '../../Testimonials/Testimonials.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import emoji from '../../Assets/prime3.png';
import emoji2 from '../../Assets/prime2.png';
import emoji5 from '../../Assets/prime1.png';
import wave from '../../Assets/wave.svg';
import kite from '../../Assets/kite.svg';

const Testimonials = () => {
    const testimonials = [
        {
            name: "Mery Murmu",
            role: "Parent",
            emoji: emoji5,
            description1: "“My daughter Aahana, in Class 5F, has been at Baldwin Girls' High School since 2017. She's grown into a confident pre-teen under the guidance of dedicated teachers. ",
            description2: "The school balances extracurricular and academic activities well, and teachers provide valuable feedback and encourage the development of hidden talents. Overall, it's been a very positive experience. Keep up the good work.”",
        },


        {
            name: "Swetha Prem",
            role: "Parent",
            emoji: emoji2,
            description1: "“I'm immensely pleased with my daughter's education at Baldwin Girls’ High School. ",
            description2: " The staff are polite and cooperative, along with their use of innovative learning styles like smart boards, have made learning enjoyable for all students. I extend my heartfelt congratulations to the school management for their endless dedication and efforts.”",
        },
        {
            name: "lyswarya Abirami.N",
            role: "Parent",
            emoji: emoji,  
              
            description1: "“My daughter Anuska had a fantastic time at Baldwin Girls' High School in 5th E last year. With caring teachers like Ms. Mary Leone and a positive atmosphere, she settled in quickly.",
            description2: "Supportive teachers helped her excel academically and gain confidence. Anuska felt safe, made friends easily, and was happy at school. We're thrilled with BGHS and anticipate more success ahead.”"


            
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500, 
        autoplay: true,
        autoplaySpeed: 3000, 
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='position-relative mt-margin parentallign'>
            <img className='kite' src={kite} alt='kite' />
            <img className='wave' src={wave} alt='wave' />
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>Testimonials</h2>
                </div>
                <p className='mb-4 fw-600' style={{ color: "#2D306F" }}>What Parents have to say about BGHS</p>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            name={testimonial.name}
                            role={testimonial.role}
                            emoji={testimonial.emoji}
                            description1={testimonial.description1}
                            description2={testimonial.description2}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;
