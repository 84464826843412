import React from 'react'
import './Games.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import indoor1 from '../../Assets/sports/indore1.jpg'
import indoor2 from '../../Assets/sports/indore2.jpg'

import outdoor1 from '../../Assets/sports/outdoor1.JPG'
import outdoor2 from '../../Assets/sports/outdoor2.JPG'
import outdoor3 from '../../Assets/sports/outdoor3.JPG'
import outdoor4 from '../../Assets/sports/outdoor4.JPG'
import outdoor5 from '../../Assets/sports/outdoor5.JPG'
import outdoor6 from '../../Assets/sports/outdoor6.JPG'
import outdoor7 from '../../Assets/sports/outdoor7.JPG'
import outdoor8 from '../../Assets/sports/outdoor8.JPG'
import outdoor9 from '../../Assets/sports/outdoor9.JPG'
import outdoor10 from '../../Assets/sports/outdoor10.JPG'
// import outdoor11 from '../../Assets/sports/outdoor11.JPG'
// import outdoor12 from '../../Assets/sports/outdoor12.JPG'
import outdoor13 from '../../Assets/sports/outdoor13.JPG'
import outdoor14 from '../../Assets/sports/outdoor14.JPG'
import outdoor15 from '../../Assets/sports/outdoor15.JPG'
import outdoor16 from '../../Assets/sports/outdoor16.JPG'
import outdoor17 from '../../Assets/sports/outdoor17.JPG'
import outdoor18 from '../../Assets/sports/outdoor18.JPG'
import outdoor19 from '../../Assets/sports/outdoor19.JPG'
import outdoor20 from '../../Assets/sports/outdoor20.JPG'
import outdoor21 from '../../Assets/sports/outdoor21.JPG'
import outdoor22 from '../../Assets/sports/outdoor22.JPG'
const Games = () => {
    const settings = {
        dots: false,
        infinite: true,
       
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,        
    };

    return (
        <div>
            <div class="blue-content blue-bg"><div class="container"><h3>GALLERY</h3></div></div>
        <div className='container mt-margin facilities-img facilities-imgSport top15'>
            {/* <div className='position-relative mb-5'>
                <h2 className='line'>NEWS</h2>
            </div> */}
            <Tabs>
                <TabList>
                    <Tab className='tab-label'>OUTDOOR GAMES </Tab>
                    <Tab className='tab-label'>INDOOR GAMES</Tab>
                </TabList>
<p style={{marginBottom:"50px"}}></p>

<TabPanel>
                <Slider {...settings}>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor6} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor2} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor3} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor4} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor5} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor1} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor7} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor8} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor9} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor10} alt='lab'/>
                        </div>
                        
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor13} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor14} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor15} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor16} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor17} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor18} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor19} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor20} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor21} alt='lab'/>
                        </div>
                        <div className='position-relative'>
                            <img className='w-100' src={outdoor22} alt='lab'/>
                        </div>
                        
                    </Slider>
                </TabPanel>
                <TabPanel>
                    <Slider {...settings}>
                        <div className='position-relative'>
                            <img className='w-100' src={indoor1} alt='lab'/>
                           
                        </div>
                        <div className='position-relative'>
                        <img className='w-100' src={indoor2} alt='lab'/>
                          
                        </div>
                        
                    </Slider>
                </TabPanel>


            </Tabs>
        </div>
        </div>
    )
}

export default Games