import React from 'react'
import eventsimg from '../../Assets/events.png'
import eventsimgmobile from '../../Assets/eventsimgmobile.png'
const Eventsbanner = () => {
  return (
    <div className='position-relative EVENTS dark'>
      <img className='w-100 desk-block' src={eventsimg} />
      <img className='w-100 mob-banner-h mob-block' src={eventsimgmobile} />
      <div className='event-banner-content'>
        <h2>Events</h2>
        <div className='breadcrumbs'>
          <span className='border-left'></span>
          <span>Academic Events</span>
          <span className='grey-round'></span>
          <span>Cultural Events</span>
          <span className='grey-round'></span>
          <span>STEM Events</span>
          <span className='border-right'></span>
        </div>
      </div>
    </div>
  )
}

export default Eventsbanner