import React, { useEffect }  from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './AdmissionNews.css'
import file_text from '../Assets/file-text.svg'
import users from '../Assets/users.svg'
import bookopen from '../Assets/book-open.svg'
import award from '../Assets/award.svg'
import star from '../Assets/star3.png'
import topper1 from '../Assets/topper1.png'
import topper2 from '../Assets/topper2.png'
import topper3 from '../Assets/topper3.png'
import topper4 from '../Assets/topper4.png'
import topper5 from '../Assets/topper5.png'
import topper6 from '../Assets/topper6.png'
import topper7 from '../Assets/topper7.png'
import topper8 from '../Assets/topper8.png'
import topper9 from '../Assets/topper9.png'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import star from '../Assets/star.svg'
const AdmissionNews = () => {
    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
      }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500, autoplay: true,
        autoplaySpeed: 3000,  arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,        
    };
    return (
       
        <div className='position-relative AdmissionNews ptt-padding'>
            <a className='custom-btn admission-btn'>Admission News</a>
                    <marquee><p className='text-center fw-600' style={{ color: "#F37A67",fontWeight:"700" }}>ADMISSION OPEN FOR THE ACADEMIC YEAR 2025-2026- FOR DIRECT ADMISSION TO BALDWIN GIRLS’ HIGH SCHOOL CONTACT +91 9148780138 </p></marquee>
            <div className='container' style={{ position: "relative" }}>
                <div className='flex-coloumn custom-flex'>
                    <h1 className='text-center fw-600 h1mob'  style={{color:"#353597"}} >ICSE RESULTS - 2024 (100%)</h1>
                    <p className='text-center fw-600' style={{ color: "#F37A67" }}>We are immensely proud of our students' achievements and the dedication of our teachers and staff.</p>
                    <h2 style={{ color: "#5B59B1" }}>Toppers</h2>
                    
                        <Slider {...settings}>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper1} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper2} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper3} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper4} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper5} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper6} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper7} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper8} alt='board' />
                            </div>
                            <div className='position-relative'>
                                <img className='topper-sec' src={topper9} alt='board' />
                            </div>
                        </Slider>
                   
                    <div className='mt-margin50'>
                        <Row style={{justifyContent:"center"}}>
                            <Col md={4} xs={12}>
                                <div className='resultCard' data-aos="fade-up" data-aos-duration="2000">
                                    <p className='resultoverview' >Overview of results </p>
                                    <div className='resultCard-content' >
                                        <p style={{color:"#FF54A6"}}>100 % results in 2024</p>
                                        <p>Number of students : 457</p>
                                        <p>Number passed : 457 </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12}>
                            <div className='resultCard' data-aos="fade-up" data-aos-duration="2000">
                                    <p className='resultoverview' >Academic Achievements</p>
                                    <div className='resultCard-content' >
                                        <p>95% & above : 37 students</p>
                                        <p>90% - 94.9% : 86 students</p>
                                        <p>75%-89.9% ：221 students</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <h1 className='trust' style={{ color: "#5B59B1", textAlign: "center", marginBottom :"30px" }}>Legacy of Trust, 140+ years of Excellence</h1>
                    <div className='legacy' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='c-card'>
                            <img src={file_text} alt='legacy-img' />
                            <p>ISO</p>
                            <p>Certified</p>
                        </div>
                        <div className='c-card'>
                            <img src={users} alt='legacy-img' />
                            <p>250k+</p>
                            <p>Total Learners</p>
                        </div>
                        <div className='c-card'>
                            <img src={bookopen} alt='legacy-img' />
                            <p>25+</p>
                            <p>Programs</p>
                        </div>
                        <div className='c-card'>
                            <img src={award} alt='legacy-img' />
                            <p>Rank <span>2</span>nd </p>
                            <p>India School Merit</p>
                        </div>
                        <div className='c-card'>
                            <img src={star} alt='legacy-img' />
                            <p>Rank <span>2</span>nd </p>
                            <p>Education World </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdmissionNews